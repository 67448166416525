import { useState } from 'react'
import { BillerType, useGetStatus } from '@genoa/middle-end'
import { getDateTime, getDateTimeFromISO } from '@genoa/utils'
import { DateTime } from 'luxon'

import {
  resetCustomerStatusAction,
  ResetLoadedPayload,
  setCustomerStatusAction,
} from '../../../modules/flex2/customer-status'
import { useLogger } from '../../../providers'
import { useAccount } from '../../use-account'
import { useReduxAction } from '../../use-redux-action'
import { useAccountStates } from '../identity/use-account-states'
import { useResolveCustomerType } from './use-resolve-customer-type'

export const useCurrentGetCustomerStatus = () => {
  const [, getStatus] = useGetStatus()
  const setCustomerStatusState = useReduxAction(setCustomerStatusAction)
  const resetCustomerStatus = useReduxAction<ResetLoadedPayload | void>(resetCustomerStatusAction as any)
  const { customer, billerConnection, isActive } = useAccount()
  // This hack needed to fix a weird edge case where the request aborts if there is one already in flight.
  // this causes an error to flash on the rent screen for a second
  const [inFlight, setInFlight] = useState(false)
  const { resolveCustomerStatusAccountState } = useAccountStates()
  const today = getDateTime()
  const { resolveCustomerType } = useResolveCustomerType()
  const flexAnywhereBillerTypes = [BillerType.OUT_OF_NETWORK, BillerType.FLEX_ANYWHERE]
  const loggerV2 = useLogger('useSetFeatureStatusState')

  async function doFetch(date: DateTime) {
    const queryDate = date.toFormat('yyyy-MM-dd')
    const response = await getStatus({
      customerId: customer?.public_id || '',
      billerAccountPublicId: billerConnection?.biller_account_public_id || '',
      queryDate,
    })
    if (response.status !== 200) {
      loggerV2.warn(
        'useCurrentGetCustomerStatus - doFetch',
        response.statusText || `${response.status} for ${queryDate}`
      )
      resetCustomerStatus({ loaded: true })
      return null
    }
    return response
  }
  async function refreshCurrentCustomerStatus() {
    if (!inFlight && isActive) {
      setInFlight(true)
      return doFetch(today.set({ day: 1 }))
        .then((response) => {
          if (response) {
            if (response?.data?.show_next_month_date) {
              const switchOverDate = getDateTimeFromISO(response.data.show_next_month_date)
              if (today >= switchOverDate) {
                return getNextMonth()
              }
            }
            setCustomerStatusState({
              initialized: true,
              loaded: true,
              customerStatus: { ...response.data },
              accountState: resolveCustomerStatusAccountState(response.data),
              userType: resolveCustomerType(response.data),
              isFlexAnywhere: flexAnywhereBillerTypes.includes(response.data.biller_type),
            })
          }
        })
        .catch((error: any) => {
          loggerV2.error('useCurrentGetCustomerStatus - refreshCurrentCustomerStatus', `error: ${error?.message}`)
        })
        .finally(() => setInFlight(false))
    }
  }

  async function getNextMonth() {
    return doFetch(today.plus({ month: 1 }).startOf('month'))
      .then((response) => {
        if (response) {
          setCustomerStatusState({
            initialized: true,
            loaded: true,
            customerStatus: { ...response.data },
            accountState: resolveCustomerStatusAccountState(response.data),
            userType: resolveCustomerType(response.data),
            isFlexAnywhere: flexAnywhereBillerTypes.includes(response.data.biller_type),
          })
        }
      })
      .catch((error: any) => {
        loggerV2.error('useCurrentGetCustomerStatus - getNextMonth', `error: ${error?.message}`)
      })
  }

  return { refreshCurrentCustomerStatus }
}
