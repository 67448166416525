import { Analytics } from '@genoa/analytics'

/**
 * Enhanced Tracking controls if a custom Amplitude event is forwarded to Google Tag Manager with the Amplitude Google
 * Tag Manager Destination Plugin
 */

const { Events: E } = Analytics
const ALL_ENHANCED_TRACKING_EVENTS: Set<Analytics.Events> = new Set([E.ACCOUNT_CREATED, E.PHONE_VERIFIED])

export const allowsEnhancedTracking = (evt: any) => ALL_ENHANCED_TRACKING_EVENTS.has(evt)
