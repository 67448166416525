import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { SLCHowToPayFlexBackContainer } from '../../common/slc/pay-flex-back'

export const OnboardingSLCHowToPayFlexBackContainer = () => {
  const navigate = useNavigate()

  return (
    <SLCHowToPayFlexBackContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_HOW_TO_PAY_FLEX_BACK}
      onNext={() => navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)}
    />
  )
}
