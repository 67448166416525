import { useMemo } from 'react'
import { SLCType } from '@genoa/domain'
import { replaceContent, SLC_DEPOSIT_MODAL, SLC_MONTHLY_RATE_MODAL } from '@genoa/screen-content'
import { ordinalSuffixFor, toFormattedDollars } from '@genoa/utils'
import { Text } from '@chakra-ui/react'

import { useModal } from '../../../../../contexts'
import { useSecureLineOfCredit } from '../../../../../hooks'
import { useFees } from '../../../../../providers'
import { PaymentScheduleItemProps } from '../../../../components'

export interface useInstallmentPaymentItemsOptions {
  readonly showInterestFeeInfoModal: (fee: number) => void
}

export const useInstallmentPaymentItems = ({ showInterestFeeInfoModal }: useInstallmentPaymentItemsOptions) => {
  const { installments, offerMonthlyRent } = useFees()
  const { isSimplifiedPaymentsEnabled, slcType } = useSecureLineOfCredit()
  const modal = useModal()
  const isClassicSLC = slcType === SLCType.CLASSIC

  return useMemo(() => {
    if (installments.length !== 2) {
      return []
    }

    const firstInstallment = installments.find((installment) => installment.processingFeeAmount)!
    const secondInstallment = installments.find((installment) => !installment.processingFeeAmount)!

    const firstPayment: PaymentScheduleItemProps = {
      label: '1st payment',
      amount: toFormattedDollars(firstInstallment.totalAmount),
      lineItems: [
        { label: `${ordinalSuffixFor(firstInstallment.day)} of the month` },
        { label: 'Payment subtotal', amount: toFormattedDollars(firstInstallment.baseAmount) },
        {
          label: `Monthly interest (${firstInstallment.processingFeePercentage}%)`,
          amount: toFormattedDollars(firstInstallment.processingFeeAmount),
          moreInfo: () => showInterestFeeInfoModal(firstInstallment.processingFeePercentage),
        },
      ],
    }

    const secondPayment: PaymentScheduleItemProps = {
      label: '2nd payment',
      amount: toFormattedDollars(secondInstallment.totalAmount),
      lineItems: [
        {
          label: `${ordinalSuffixFor(secondInstallment.day)} of the month`,
        },
      ],
    }

    const deposit: PaymentScheduleItemProps = {
      ...secondPayment,
      label: 'Deposit',
      lineItems: [{ label: 'When rent is due' }],
    }

    const slcClassicDeposit: PaymentScheduleItemProps = {
      amount: toFormattedDollars(secondInstallment.totalAmount),
      label: 'When rent is due',
      lineItems: [
        {
          label: 'Deposit',
          moreInfo: () =>
            modal.show({
              title: SLC_DEPOSIT_MODAL.TITLE,
              render: () => (
                <Text whiteSpace="pre-wrap">
                  {replaceContent(SLC_DEPOSIT_MODAL.CONTENT, {
                    rentAmount: toFormattedDollars(offerMonthlyRent),
                    basePaymentAmount: toFormattedDollars(firstInstallment.baseAmount),
                    monthlyRateAmount: toFormattedDollars(firstInstallment.processingFeeAmount),
                  })}
                </Text>
              ),
              cta: SLC_DEPOSIT_MODAL.CTA,
            }),
        },
      ],
    }

    const slcClassicPayment: PaymentScheduleItemProps = {
      amount: toFormattedDollars(firstInstallment.totalAmount),
      label: `${ordinalSuffixFor(firstInstallment.day)} of the month`,
      lineItems: [
        {
          label: 'Payment',
          amount: toFormattedDollars(firstInstallment.baseAmount),
        },
        {
          label: `${firstInstallment.processingFeePercentage}% monthly rate (${
            firstInstallment.processingFeePercentage * 12
          }% APR)`,
          amount: toFormattedDollars(firstInstallment.processingFeeAmount),
          moreInfo: () =>
            modal.show({
              title: SLC_MONTHLY_RATE_MODAL.TITLE,
              render: () => (
                <Text whiteSpace="pre-wrap">
                  {replaceContent(SLC_MONTHLY_RATE_MODAL.CONTENT, {
                    monthlyRatePercent: firstInstallment.processingFeePercentage,
                    basePaymentAmount: toFormattedDollars(firstInstallment.baseAmount),
                    ordinal: ordinalSuffixFor(firstInstallment.day),
                    aprPercentage: firstInstallment.processingFeePercentage * 12,
                  })}
                </Text>
              ),
              cta: SLC_MONTHLY_RATE_MODAL.CTA,
            }),
        },
      ],
    }

    if (isClassicSLC) {
      return [slcClassicDeposit, slcClassicPayment]
    }

    if (isSimplifiedPaymentsEnabled) {
      return [deposit, { ...firstPayment, label: 'Payment' }]
    }

    return [deposit, firstPayment, secondPayment]
  }, [installments, isSimplifiedPaymentsEnabled, isClassicSLC])
}
