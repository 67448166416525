import { GetDefaultCardResponse, useGetDefaultCard } from '@genoa/middle-end'
import { AxiosPromise } from 'axios'

import { resetCustomerCardAction, setCustomerCardAction } from '../../../modules/flex2/customer-wallet'
import { useLogger } from '../../../providers'
import { useReduxAction } from '../../use-redux-action'

export const useGetWallet = () => {
  const loggerV2 = useLogger('useGetWallet')
  const [{ loading: loadingDefaultCard }, getCard] = useGetDefaultCard()
  const setWalletState = useReduxAction(setCustomerCardAction)
  const resetCustomerCard = useReduxAction<void>(resetCustomerCardAction)

  const getDefaultCard = (customerId: string): AxiosPromise<GetDefaultCardResponse> => {
    if (!customerId) {
      loggerV2.error('getDefaultCard', 'getDefaultCard called without a customerId')
      return Promise.resolve({}) as AxiosPromise<GetDefaultCardResponse>
    }
    return getCard({ customerId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setWalletState({ defaultCard: response.data.card })
        } else if (response.status !== 404) {
          loggerV2.error('getWallet', 'unexpected status', { status: response.status })
          resetCustomerCard()
        }
        return response
      })
      .catch((error: any) => {
        loggerV2.error('getWallet', `error: ${error?.message}`)
        return error
      })
  }

  return {
    getDefaultCard,
    loadingDefaultCard,
  }
}
