import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType, SLCType } from '@genoa/domain'
import { OnboardingStep } from '@genoa/middle-end'

import { useAuthState } from '../../../../../../contexts'
import { useSecureLineOfCredit } from '../../../../../../hooks'
import { useHandleRegisterActionLog, useOnboardingStatus } from '../../../../../../hooks/flex2/onboarding-status'
import { useGetWallet } from '../../../../../../hooks/flex2/wallet'
import { useCustomerWalletState } from '../../../../../../modules/flex2/use-state'
import { useAnalytics, useLogger } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { ConfirmCard } from './ConfirmCard'

export const V2ConfirmCardContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { user } = useAuthState()
  const loggerV2 = useLogger('V2ConfirmCardContainer')

  const { getDefaultCard } = useGetWallet()

  const customerWalletState = useCustomerWalletState()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const { resolveNextStep } = useOnboardingStatus()
  const [cardLast4Digits, setCardLast4Digits] = useState('-')
  const [cardNetwork, setCardNetwork] = useState('-')
  const [cardType, setCardType] = useState('-')
  const { slcType } = useSecureLineOfCredit()

  useEffect(() => {
    if (!customerWalletState.initialized) {
      if (user?.uid) {
        // If the default card is missing on state try fetching it
        getDefaultCard(user?.uid)
      } else {
        loggerV2.error('initialize default card', 'default card is missing on confirm card')
      }
      return
    }
    const defaultCard = customerWalletState.defaultCard
    setCardLast4Digits(defaultCard.card_mask)
    setCardNetwork(defaultCard.brand)
    setCardType(defaultCard.card_type ?? '--')
  }, [customerWalletState, user?.uid])

  const handleLinkDifferentCard = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONFIRM_CARD_LINK_DIFFERENT_CLICKED)
    navigate(Routes.Onboarding.CARD_REGISTER, {
      state: {
        analyticsScreenName: Analytics.Screens.ADD_CARD_ONBOARDING_CONFIRM_CARD,
        nextRoute: Routes.Onboarding.SOFT_CREDIT_CHECK,
      },
    })
  }, [])

  const handleConfirm = async () => {
    analytics.logEvent(Analytics.Events.CONFIRM_CARD_CTA_CLICKED)
    await handleRegisterActionLog(ActionLogType.CREATE_CARD)
    if (slcType === SLCType.CLASSIC) {
      return navigate(Routes.Onboarding.OFFER_DETAILS)
    }
    resolveNextStep(OnboardingStep.PAYMENT_METHOD_SETUP, () => navigate(Routes.Onboarding.SOFT_CREDIT_CHECK))
  }

  return (
    <ConfirmCard
      analyticsScreenName={Analytics.Screens.ONBOARDING_CONFIRM_CARD}
      onLinkDifferentCard={handleLinkDifferentCard}
      cardLast4Digits={cardLast4Digits}
      cardNetwork={cardNetwork}
      onPressConfirm={handleConfirm}
      cardType={cardType}
      isLoading={loadingRegisterActionLog}
    />
  )
}
