import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { replaceContent } from '@genoa/domain'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'

import { useAutopilotEligibility, useFlexAnywhereUser } from '../../../hooks'
import { useContent } from '../../../providers/content'
import { CongratsFlag as CongratsImageFlag, HeroBouquet as CongratsImage } from '../../assets'
import { Headline1, Text } from '../../components'
import { AppStoreButton } from '../../components/AppStoreButton'
import { PlayStoreButton } from '../../components/PlayStoreButton'
import { FullScreenSpinnerLoading } from '../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { BasePageLayout } from '../../layouts'

type CongratsProps = {
  analyticsScreenName: Analytics.Screens
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
}

export const Congrats = (props: CongratsProps) => {
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, isLoadingEligibility } = useAutopilotEligibility()

  const image = isUserAutopayEnabled ? <CongratsImageFlag /> : <CongratsImage />
  const {
    setUserContentRule,
    content: { CONGRATS },
  } = useContent()

  useEffect(() => {
    if (!isOutOfNetworkUser) {
      const contentRule = isUserAutopayEnabled ? 'CONGRATS_NEW_CONTENT' : 'CONGRATS_AUTOPILOT_OFF_CONTENT'
      setUserContentRule(contentRule)
    }
  }, [isOutOfNetworkUser, isUserAutopayEnabled])

  const today = getDateTime()
  const resumeDate = getResumeDate(today)
  const renderNextAvailableResumeDate = () => {
    return `${resumeDate.monthLong} ${resumeDate.year}`
  }

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH={isUserAutopayEnabled ? '15px' : '42px'} />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="CongratsPage">
        <Flex align="center" justify="center">
          {image}
        </Flex>

        <Box minH={isUserAutopayEnabled ? '36px' : '20px'} />

        <Headline1 textAlign="center">{CONGRATS.HEADER}</Headline1>

        <Box minH={'16px'} />

        <Text textAlign="center">{CONGRATS.BODY}</Text>
        <Box minH={'24px'} />
        <Text textAlign="center" fontWeight={'bold'}>
          {replaceContent(CONGRATS.BODY_BOLD, { date: renderNextAvailableResumeDate() })}
        </Text>

        <Box minH={'32px'} />

        <Text textAlign="center" fontWeight={'bold'}>
          {CONGRATS.DOWNLOAD}
        </Text>

        <Flex
          flexShrink={0}
          direction="column"
          align="center"
          justify="center"
          bg="cloud"
          borderRadius={'8px'}
          px={'15px'}
          py={'30px'}
        >
          <Flex {...storeBadgeStyles}>
            <AppStoreButton />
          </Flex>
          <Box minH={'24px'} />
          <Flex {...storeBadgeStyles}>
            <PlayStoreButton />
          </Flex>
        </Flex>
      </Flex>

      <Box minH={'30px'} />
    </BasePageLayout>
  )
}
