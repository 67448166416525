import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as Routes from '../routing/constants'

export const useNavigation = () => {
  const navigate = useNavigate()

  const navigateToLogIn = () => {
    navigate(Routes.Auth.PHONE)
  }

  const navigateToSignIn = () => {
    navigate(Routes.Auth.PHONE_CREATE_ACCOUNT)
  }

  return {
    navigateToSignIn,
    navigateToLogIn,
  }
}
