import { ReactNode } from 'react'
import { Controller, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import lowerCase from 'lodash/lowerCase'

import { SmallText } from '..'
import { MoneyInput, TextInput } from '../Input'

type Inputs = typeof TextInput | typeof MoneyInput

type FieldProps = {
  label: string | ReactNode
  name: string
  defaultValue?: string
  autoCapitalize?: boolean
  control: any
  error?: FieldError
  input?: Inputs
  foreground?: string
  background?: string
  disabled?: boolean
  password?: boolean
  onChange?: (value: string) => unknown
  onFocus?: () => void
  testID?: string
  autoComplete?: string
  maxLength?: number
  placeholder?: string
  preventChangeValue?: boolean
}

export function Field(props: FieldProps) {
  const { control, label, autoCapitalize, error, name, defaultValue, onFocus } = props
  let errorMessage: string
  if (error && error.message) {
    const formatted = lowerCase(name)
    errorMessage = error.message.replace(name, formatted).replace('a required field', 'required')
  }

  const Input = props.input || TextInput
  const onTextChange = (
    value: string,
    onChange: ControllerRenderProps<FieldValues, FieldValues[string]>['onChange']
  ) => {
    props.onChange && props.onChange(value)
    if (!props.preventChangeValue) {
      onChange(value)
    }
  }

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <>
            <SmallText color={props.foreground}>{label}</SmallText>

            <Box minH="5px" />

            <Input
              onBlur={onBlur}
              onFocus={onFocus}
              onTextChange={(value) => onTextChange(value, onChange)}
              text={value}
              error={!!errorMessage}
              password={props.password}
              disabled={props.disabled}
              autoCapitalize={autoCapitalize}
              background={props.background}
              foreground={props.foreground}
              defaultValue={defaultValue}
              testID={props.testID}
              autoComplete={props.autoComplete}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
            />

            <Box minH="5px" />

            <Box visibility={errorMessage ? 'visible' : 'hidden'} color="#E53A3A">
              <SmallText hasError={!!errorMessage}>{errorMessage}</SmallText>
            </Box>
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue || ''}
    />
  )
}
