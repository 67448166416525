import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'
import { GetSLCStatusResponse } from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { RootState } from '../../modules'
import { useGetSLCStatusQuery } from '../../modules/flexApi'
import { useAnalytics } from '../../providers'
import { useReduxSelector } from '../use-redux-selector'

interface SecureLineOfCreditFields {
  isEnabledForSLC: boolean
  isSimplifiedPaymentsEnabled: boolean
  isPricingEnabled: boolean
  isFlagEnabledForSLC?: boolean
  isFlagEnabledForCreditBuilder?: boolean
  slcType?: SLCType
  isLoadingSLC?: boolean
  isRefetchingSLC?: boolean
  error?: any // need to get the type for this
}

interface SecureLineOfCreditStatus extends SecureLineOfCreditFields {
  refetchSLC: () => Promise<SecureLineOfCreditFields>
}

export const useSecureLineOfCredit = (refetchOnMountOrArgChange?: boolean): SecureLineOfCreditStatus => {
  const { uid, isAnonymous } = useAuthState()
  const firebaseInitialized = useReduxSelector((state: RootState) => state.auth?.firebaseInitialized)
  const analytics = useAnalytics()

  const handleStatus = (response: GetSLCStatusResponse | undefined) => {
    const isEnabledForSLC = response?.data?.enabled ?? false
    const isSimplifiedPaymentsEnabled = (isEnabledForSLC && response?.data?.simplified_payments) ?? false

    return {
      isEnabledForSLC,
      isSimplifiedPaymentsEnabled,
      isPricingEnabled: response?.data?.pricing_enabled ?? false,
      isFlagEnabledForCreditBuilder: response?.data?.credit_builder_enabled ?? false,
      slcType: response?.data?.slc_type,
    }
  }

  const {
    data: SLCStatusData,
    isLoading,
    refetch,
    isFetching,
    error,
  } = useGetSLCStatusQuery(
    { customerId: uid! },
    {
      skip: !uid || !firebaseInitialized || isAnonymous || isAnonymous === null,
      refetchOnMountOrArgChange,
    }
  )

  const refetchSLC = async () => {
    const response = await refetch()
    return handleStatus(response?.data)
  }

  useEffect(() => {
    const status = handleStatus(SLCStatusData)
    if (status?.slcType) {
      analytics.setUserProperty(Analytics.UserProperties.SLC_TYPE, status.slcType)
    }
  }, [SLCStatusData])

  return {
    ...handleStatus(SLCStatusData),
    isFlagEnabledForSLC: true,
    isLoadingSLC: isLoading,
    isRefetchingSLC: isFetching,
    refetchSLC,
    error,
  }
}
