import { useEffect } from 'react'

import { setEmbedAction } from '../../modules/flex2/embed'
import { useReduxAction } from '../use-redux-action'

export const useSetEmbedParams = () => {
  const setEmbed = useReduxAction(setEmbedAction)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const isEmbed = urlParams.get('e') === '1'
    const client = urlParams.get('client')
    const token = urlParams.get('token')

    if (isEmbed && client && token) {
      setEmbed({ client, isEmbed, token })
    }
  }, [])
}
