import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { actionLogToSelectedEvent, SetupAutopilotAction } from '@genoa/domain'

import { useAutopilotEligibility, useSetAutopilot } from '../../../../../hooks'
import { useAnalytics } from '../../../../../providers'
import { Gap } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { SLCAutopaySetup } from './SLCAutopaySetup'

export interface SLCAutopaySetupContainerProps {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

export const SLCAutopaySetupContainer = (props: SLCAutopaySetupContainerProps) => {
  const analytics = useAnalytics()
  const location = useLocation()
  const navigate = useNavigate()
  const { isUserAutopayEnabled } = useAutopilotEligibility()
  const [selectedOption, setSelectedOption] = useState<SetupAutopilotAction | undefined>(undefined)

  const onSuccess = () => {
    if (location?.state?.nextRoute) {
      return navigate(location.state.nextRoute)
    }

    props.onNext()
  }

  const { setAutopilot, loading } = useSetAutopilot({ onSuccess })

  useEffect(() => {
    if (location?.state?.prefillSelection) {
      setSelectedOption(isUserAutopayEnabled ? SetupAutopilotAction.AUTOPAY_ON : SetupAutopilotAction.EPISODIC_ON)
    }
  }, [])

  const onSelectOption = (option: SetupAutopilotAction) => {
    setSelectedOption(option)
    return analytics.logEvent(actionLogToSelectedEvent[option])
  }

  const onConfirm = async () => {
    if (!selectedOption) {
      return undefined
    }

    await setAutopilot(selectedOption)
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <SLCAutopaySetup
        confirmDisabled={loading}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        onConfirm={onConfirm}
      />
    </BasePageLayout>
  )
}
