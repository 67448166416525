import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL } from '@genoa/screen-content'
import { useTheme } from '@emotion/react'

import { useAnalytics, useFlexLinks } from '../../providers'
import { SmallText } from '../../views/components'
import { useShowErrorMessageModal } from '.'

export const useDefaultErrorModals = () => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()
  const theme = useTheme()
  const { showErrorMessage } = useShowErrorMessageModal()

  const displayTooManyTriesModal = () => {
    showErrorMessage(
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.HEADER,
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.BODY,
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.CTA
    )
  }

  const handleContactSupport = (errorData?: string) => {
    analytics.logEvent(Analytics.Events.CONTACT_SUPPORT_CTA_CLICKED, { origin: errorData })
    flexLinks.open(FlexLinks.supportTicketWeb)
  }

  const displayDefaultErrorModal = (origin = '') => {
    showErrorMessage(
      'Error',
      <>
        We weren’t able to complete your request. You can try again in a few minutes or{' '}
        <SmallText color={theme.colors.jewelPurple} onClick={() => handleContactSupport(origin)}>
          contact support
        </SmallText>{' '}
        for assistance.
      </>,
      'OK'
    )
  }

  return {
    displayTooManyTriesModal,
    displayDefaultErrorModal,
  }
}
