import { Route, Routes as RouterRoutes } from 'react-router-dom'

import { PhoneConsolidationContainer } from '../views/pages/auth/PhoneConsolidationContainer'
import { PhoneNumberContainer } from '../views/pages/auth/PhoneNumberContainer'
import { OnboardingConfirmPinContainer } from '../views/pages/onboarding/auth/OnboardingConfirmPinContainer'
import * as Routes from './constants'
import { getRouteSlugs } from './utils'

const routeSlugs = getRouteSlugs(Routes.App.AUTH, Routes.Auth)

export function AuthRoutes() {
  return (
    <RouterRoutes>
      <Route path={routeSlugs.PHONE} Component={PhoneNumberContainer} />
      <Route path={routeSlugs.PHONE_CREATE_ACCOUNT} Component={PhoneConsolidationContainer} />
      <Route path={routeSlugs.PIN} Component={OnboardingConfirmPinContainer} />
    </RouterRoutes>
  )
}
