import { Analytics } from '@genoa/analytics'
import cleanLogData from '@genoa/utils/lib/clean-log-data'
import sha256 from 'crypto-js/sha256'

import { allowsEnhancedTracking } from '../amplitude'

// FIXME: "[MRTCH-23] Embed GTM plugin in wellington" would point the following toggle to an Amplitude FF
const shouldRunNewMarTech = () => true

/**
 * sanitizes event data by stripping away PII data like email with "clean-log-data.ts" utility wrapper
 * in addition, if the event allows for enhanced tracking and the original event data contained the "email" attribute,
 * then adds back the "email" attribute as SHA-256 hashed version of the original
 */
export const sanitizeEventData = (event: Analytics.Events, data?: any) => {
  if (data === undefined) {
    // nothing to sanitize!
    return undefined
  }

  // round 1: use clean-log-data.ts
  const cleanedLogData = cleanLogData(data)
  if (!allowsEnhancedTracking(event) || !shouldRunNewMarTech()) {
    // event doesn't allow for enhanced tracking or MarTech Enhanced Tracking is turned off
    return cleanedLogData
  }

  // round 2: event requiring enhanced tracking found; add email back if one was present in the original data
  const email = cleanedLogData.email
  if (email === undefined) {
    // no email to add to data
    return cleanedLogData
  }

  // sha-256 hashed email is added and returned
  return {
    email: sha256(email).toString(),
    ...cleanedLogData,
  }
}
