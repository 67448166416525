import React from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { Box } from '@chakra-ui/react'

import { useContent } from '../../../../../providers/content'
import { Headline2, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { LocationSuggestModal } from './LocationSuggestModal'
import { PortalAddressEntryInput } from './PortalAddressEntryInput'
import { PortalAddressEntryStateDropdown } from './PortalAddressEntryStateDropdown'
import { PortalAddress, PortalAddressFormProps } from './types'

type PortalAddressEntryProps = PortalAddressFormProps & {
  errors: Partial<DeepMap<PortalAddress, FieldError>>
  onPressCTA: () => unknown
  isSubmitting: boolean
}

export const PortalAddressEntry = (props: PortalAddressEntryProps) => {
  const {
    content: { PORTAL_ADDRESS_ENTRY },
  } = useContent()

  return (
    <BasePageLayout>
      <Box minH="30px" px="lg" />
      <Headline2 mb={2}>{PORTAL_ADDRESS_ENTRY.HEADER}</Headline2>
      <SmallText>{PORTAL_ADDRESS_ENTRY.BODY}</SmallText>
      <Box mb={5} />
      <PortalAddressEntryInput
        label="Address"
        name="address_line_1"
        control={props.control}
        error={props.errors.address_line_1}
        testID="PortalAddressEntryAddressLine1Input"
      />
      <Box mb={5} />
      <PortalAddressEntryInput
        label="Apt, suite, or unit"
        name="address_line_2"
        control={props.control}
        error={props.errors.address_line_2}
        testID="PortalAddressEntryAddressLine2Input"
        maxLength={15}
      />

      <Box mb={5} />
      <PortalAddressEntryInput
        label="Zip code"
        name="zip"
        control={props.control}
        error={props.errors.zip}
        testID="PortalAddressEntryZipInput"
      />
      <Box mb={5} />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="70%">
          <PortalAddressEntryInput
            label="City"
            name="city"
            control={props.control}
            error={props.errors.city}
            testID="PortalAddressEntryCityInput"
          />
        </Box>
        <Box w="20px" />
        <Box>
          <PortalAddressEntryStateDropdown
            label="State"
            name="state"
            control={props.control}
            error={props.errors.state}
            testID="PortalAddressEntryStateInput"
          />
        </Box>
      </Box>
      <Box mb={5} />
      <PrimaryButton processing={props.isSubmitting} onClick={props.onPressCTA} testID="PortalAddressEntrySaveButton">
        {PORTAL_ADDRESS_ENTRY.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}
