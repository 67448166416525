import React from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { RentAmountState } from '../../../../modules'
import { useContent } from '../../../../providers/content'
import { Field, Headline1, MoneyInput, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { rentValidator } from './common'
import { RentAmountChanges } from './RentAmountChanges'

export type InitiateOfferRentAmountProps = {
  submitRentAmount: (RentAmount: RentAmountState) => unknown
  rentAmount?: RentAmountState
  isLoading: boolean
  errors: {
    amount?: FieldError
  }
  onNext: (amount: number) => unknown
  onBack?: () => unknown
  analyticsScreenName: Analytics.Screens
  rentAmountChangeModalVisible: boolean
  setRentAmountChangeModalVisible: (value: boolean) => unknown
  showConfirmText: boolean
}

export const InitiateOfferRentAmount = (props: InitiateOfferRentAmountProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<RentAmountState>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object({
        amount: rentValidator,
      })
    ),
  })
  const {
    content: { RENT_AMOUNT },
  } = useContent()

  const onSubmit = handleSubmit((data: RentAmountState) => {
    if (!props.isLoading && data.amount) {
      props.submitRentAmount(data)
      props.onNext(parseFloat(data.amount))
    }
  })

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH={'30px'} />

      <Box flex={1} px="lg">
        <Box>
          <Headline1>{props.showConfirmText ? RENT_AMOUNT.CONFIRM_HEADER : RENT_AMOUNT.HEADER}</Headline1>

          <Box minH={'24px'} />

          <SmallTextStyled>{RENT_AMOUNT.BODY}</SmallTextStyled>
        </Box>

        <Box minH={'32px'} />

        {props.rentAmount && (
          <Field
            input={MoneyInput}
            label={RENT_AMOUNT.RENT_INPUT_LABEL || 'Monthly rent'}
            name="amount"
            control={control}
            defaultValue={props.rentAmount.amount}
            error={errorsForm.amount || props.errors.amount}
            testID="RentAmountMonthlyRentInput"
          />
        )}
        <Box minH="24px" />
        <SmallTextStyled>
          <AmountChangesText onClick={() => props.setRentAmountChangeModalVisible(true)}>
            {RENT_AMOUNT.AMOUNT_CHANGES}
          </AmountChangesText>
        </SmallTextStyled>

        <Box minH="60px" />
        <Box>
          <PrimaryButton
            testID="RentAmountNext"
            onClick={onSubmit}
            disabled={props.isLoading}
            processing={props.isLoading}
          >
            {RENT_AMOUNT.CTA}
          </PrimaryButton>
        </Box>
      </Box>
      <Box style={{ zIndex: 2 }}>
        <RentAmountChanges
          visible={props.rentAmountChangeModalVisible}
          header={RENT_AMOUNT.AMOUNT_CHANGE_MODAL_HEADER}
          bodyOne={RENT_AMOUNT.AMOUNT_CHANGE_MODAL_BODY_ONE}
          bodyTwo={RENT_AMOUNT.AMOUNT_CHANGE_MODAL_BODY_TWO}
          cta={RENT_AMOUNT.AMOUNT_CHANGE_MODAL_CTA}
          onClose={() => props.setRentAmountChangeModalVisible(false)}
        />
      </Box>
    </BasePageLayout>
  )
}

const SmallTextStyled = styled(SmallText)`
  margin-bottom: 0;
`

const AmountChangesText = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.jewelPurple};
  cursor: pointer;
`
