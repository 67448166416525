import React, { useCallback, useMemo } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useReduxSelector } from '../../../../hooks'
import { usePrefills } from '../../../../hooks/prefills'
import { RootState } from '../../../../modules'
import { useAnalytics, useFlexLinks } from '../../../../providers'
import { RentPortalDetails } from './RentPortalDetails'

type RentPortalDetailsContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventBackClicked: Analytics.Events
  analyticsEventSigninClicked: Analytics.Events
  analyticsEventWhatIfNoPortalClicked: Analytics.Events
  analyticsEventStartOverClicked: Analytics.Events
  onSignInClicked: () => unknown
  onStartOver: () => unknown
}

export const RentPortalDetailsContainer = (props: RentPortalDetailsContainerProps) => {
  const analytics = useAnalytics()
  const { resetPrefills, prefillIsActive } = usePrefills()
  const property = useReduxSelector((state: RootState) => state.propertyLinking.property)
  const portal = useReduxSelector((state: RootState) => state.propertyLinking.portalDetails)
  const flexLinks = useFlexLinks()

  const info = useMemo(() => {
    return {
      propertyName: property?.name || '',
      portalName: portal?.portal_name || property?.name || '',
      displayName: portal?.display_name || property?.name || '',
      portalLogoUrl: portal?.color_logo_url || '',
    }
  }, [property, portal])

  const handleBack = useCallback(() => {
    analytics.logEvent(props.analyticsEventBackClicked)
  }, [])

  const handleSignIn = useCallback(() => {
    analytics.logEvent(props.analyticsEventSigninClicked, {
      property: info.propertyName,
      portal: info.portalName,
    })
    props.onSignInClicked()
  }, [info])

  const handleWhatIfNoPortalAccount = useCallback(() => {
    analytics.logEvent(props.analyticsEventWhatIfNoPortalClicked, {
      property: info.propertyName,
      portal: info.portalName,
    })

    flexLinks.open(FlexLinks.noRentPortal)
  }, [flexLinks.open, info])

  const handleStartOver = useCallback(() => {
    analytics.logEvent(props.analyticsEventStartOverClicked, {
      property: info.propertyName,
      portal: info.portalName,
    })
    resetPrefills()
    props.onStartOver()
  }, [props.onStartOver, info])

  return (
    <RentPortalDetails
      onBack={handleBack}
      onSignIn={handleSignIn}
      onWhatIfNoPortalAccount={handleWhatIfNoPortalAccount}
      propertyName={info.propertyName}
      portalName={info.displayName}
      portalLogoUrl={info.portalLogoUrl}
      analyticsScreenName={props.analyticsScreenName}
      onStartOver={handleStartOver}
      showPrefills={prefillIsActive}
    />
  )
}
