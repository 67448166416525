import React, { useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../providers/content'
import { Headline1, LineDivider, PrimaryButton, SmallText, SpinnerLoading } from '../../../components'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { BasePageLayout } from '../../../layouts'
import { SecondPaymentDay } from '../customize-your-schedule/second-payment-days-list/SecondPaymentList'
import {
  PaymentScheduleBox,
  SecondPaymentListContainerV2,
} from '../customize-your-schedule/second-payment-days-list/SecondPaymentListV2'
import { DaySelectedState } from './ChooseDateContainer'
import { ReinforcementMessage, ReinforcementMessageWidget } from './ReinforcementMessageWidget'

type ChooseDateProps = {
  analyticsScreenName: Analytics.Screens
  onSelectSecondPaymentDay: (secondPaymentDay: SecondPaymentDay) => void
  filteredFirstPaymentDays: SecondPaymentDay[]
  filteredSecondPaymentDays: SecondPaymentDay[]
  isConfirmButtonDisabled: boolean
  onClickConfirmButton: () => void
  daysSelected?: DaySelectedState
  loadingScheduleOptions?: boolean
  showReinforcementMessage: ReinforcementMessage | undefined
  loading?: boolean
  isSimplifiedPaymentsEnabled: boolean
  canChooseSecondDay: boolean
  toggleCanChooseSecondDay: () => void
}

type ContentProps = {
  HEADER: string
  BODY: string
  CTA: string
  CTA_NEXT?: string
}

export const ChooseDate = (props: ChooseDateProps) => {
  const {
    content: {
      FIRST_REPAYMENT_FEEDBACK,
      SECOND_REPAYMENT_FEEDBACK,
      CHOOSE_DATE,
      CHOOSE_DATE_TWO,
      CUSTOMIZE_YOUR_SCHEDULE,
    },
  } = useContent()

  const [content, setContent] = useState<ContentProps>(CHOOSE_DATE)

  const lastScheduleDay = props.filteredSecondPaymentDays[props.filteredSecondPaymentDays.length - 1]

  useEffect(() => {
    if (!props.isSimplifiedPaymentsEnabled) {
      setContent(CHOOSE_DATE_TWO)
    }
  }, [props.isSimplifiedPaymentsEnabled])

  if (props.loading || props.filteredFirstPaymentDays?.length <= 0) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout>
      <Box px="sm">
        <Box minH="32px" />
        <Headline1>{content.HEADER}</Headline1>
        <Box minH="16px" />
        <SmallText>{content.BODY}</SmallText>
        <Box minH="32px" />
      </Box>

      <SecondPaymentListWrapper onClick={props.canChooseSecondDay ? props.toggleCanChooseSecondDay : undefined}>
        <SecondPaymentListContainerV2
          mostPopularDay={15}
          hideDateSelection={props.canChooseSecondDay}
          onSelectSecondPaymentDay={props.onSelectSecondPaymentDay}
          secondPaymentDays={props.filteredFirstPaymentDays}
          daySelected={props.daysSelected?.firstDay}
          paymentTitle={props.isSimplifiedPaymentsEnabled ? 'Payment' : '1st payment'}
          topRightText={CUSTOMIZE_YOUR_SCHEDULE?.SECOND_PAYMENT_INFO}
        />

        {!props.canChooseSecondDay && (
          <>
            <Box minH="64px" />
            <ReinforcementMessageWidget
              content={FIRST_REPAYMENT_FEEDBACK}
              daySelected={props.daysSelected?.firstDay}
              showReinforcementMessage={props.showReinforcementMessage}
            />
          </>
        )}
      </SecondPaymentListWrapper>

      <Box minH="32px" />

      {!props.isSimplifiedPaymentsEnabled && props.filteredSecondPaymentDays?.length > 0 && (
        <SecondPaymentListWrapper
          onClick={
            !props.canChooseSecondDay && props.daysSelected?.secondDay ? props.toggleCanChooseSecondDay : undefined
          }
        >
          <SecondPaymentListContainerV2
            mostPopularDay={lastScheduleDay.day < 28 ? lastScheduleDay.day : 28}
            hideDateSelection={!props.canChooseSecondDay}
            onSelectSecondPaymentDay={props.onSelectSecondPaymentDay}
            secondPaymentDays={props.filteredSecondPaymentDays}
            daySelected={props.daysSelected?.secondDay}
            paymentTitle={'2nd Payment'}
            topRightText={!props.canChooseSecondDay ? 'Next Step' : undefined}
          />

          {props.canChooseSecondDay && (
            <>
              <Box minH="64px" />
              <ReinforcementMessageWidget
                content={SECOND_REPAYMENT_FEEDBACK}
                daySelected={props.daysSelected?.secondDay}
                showReinforcementMessage={props.showReinforcementMessage}
              />
            </>
          )}
        </SecondPaymentListWrapper>
      )}

      <Box minH="64px" />

      <ConfirmButtonWrapper>
        <ConfirmSelectedDateButton
          testID="CustomizeYourScheduleConfirmButton"
          processing={props.loading}
          disabled={props.isConfirmButtonDisabled || props.loading}
          onClick={props.onClickConfirmButton}
        >
          {props.canChooseSecondDay || props.daysSelected?.secondDay || props.isSimplifiedPaymentsEnabled
            ? content.CTA
            : content.CTA_NEXT}
        </ConfirmSelectedDateButton>
      </ConfirmButtonWrapper>
    </BasePageLayout>
  )
}

const ConfirmSelectedDateButton = styled(PrimaryButton)`
  width: 100%;
`

const ConfirmButtonWrapper = styled(Flex)`
  width: 100%;
  padding: ${(props) => props.theme.fixedSizes?.xs};
  padding-top: 0;
  padding-bottom: ${(props) => props.theme.fixedSizes?.md};
`

const SecondPaymentListWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.colors.wildflower};
  padding-bottom: ${(props) => props.theme.fixedSizes?.md};
  padding-top: ${(props) => props.theme.fixedSizes?.xs};
  border-radius: ${(props) => props.theme.radii?.lg};
`
