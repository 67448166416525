import { SLCType } from '@genoa/domain'
import {
  SLC_CLASSIC_PAYMENT_SCHEDULE_CONTENT as classicContent,
  SLC_PAYMENT_SCHEDULE_CONTENT as legacyContent,
} from '@genoa/screen-content'

import { useSecureLineOfCredit } from '../../../../../hooks'
import { PaymentSchedule, PaymentScheduleItemProps } from '../../../../components'
import { SimplifiedPaymentsDisabledSchedule } from './SimplifiedPaymentsDisabledSchedule'

export interface SLCConfirmScheduleProps {
  readonly payments: readonly PaymentScheduleItemProps[]
}

export const SLCPaymentScheduleContainer = ({ payments }: SLCConfirmScheduleProps) => {
  const { isSimplifiedPaymentsEnabled, slcType } = useSecureLineOfCredit()

  const content = slcType === SLCType.CLASSIC ? classicContent : legacyContent

  const [depositPayment, ...recurringPayments] = payments

  return isSimplifiedPaymentsEnabled ? (
    <PaymentSchedule payments={payments} title={content.RECURRING_TITLE} />
  ) : (
    <SimplifiedPaymentsDisabledSchedule
      depositPayment={depositPayment}
      depositTitle={content.SP_DISABLED_DEPOSIT_TITLE}
      recurringPayments={recurringPayments}
      recurringTitle={content.RECURRING_TITLE}
    />
  )
}
