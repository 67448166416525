import { createReducer } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import * as Actions from './actions'
import { RepayNowState } from './types'

export const initialState: RepayNowState = {
  paymentAmount: 0,
  basePaymentAmount: 0,
  outstandingBalance: undefined,
  paymentLabel: '',
  paymentDate: DateTime.now(),
  userPaymentPublicId: '',
  selfPayPublicId: '',
  billTransactionId: '',
  navigationSource: '',
  delinquent: false,
  initialized: false,
  isFirstPaymentServiceIssue: false,
  isTryingToPauseOrCancel: false,
  fundEarly: false,
  nextAuthAttempt: '',
  isDeposit: false,
  isAdditionalDeposit: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetSelectedRepayNowAction, (_state, _action) => {
      return { ...initialState }
    })
    .addCase(Actions.setSelectedRepayNowAction, (_state, action) => {
      const paymentAmount = action.payload.paymentAmount ?? 0
      return { ...action.payload, paymentAmount, initialized: true }
    })
})
