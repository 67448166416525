import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { AppStoreButton } from '../AppStoreButton'
import { PlayStoreButton } from '../PlayStoreButton'

export const AppStore = () => (
  <AppButtonContainer>
    <StoreButtonContainer>
      <AppStoreButton />
    </StoreButtonContainer>
    <Box minH="24px" />
    <StoreButtonContainer>
      <PlayStoreButton />
    </StoreButtonContainer>
  </AppButtonContainer>
)

const AppButtonContainer = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cloud};
  border-radius: ${({ theme }) => theme.radii.lg};
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`

const StoreButtonContainer = styled(Flex)`
  cursor: pointer;
  max-height: ${({ theme }) => theme.components.AppStore.height};
  min-height: ${({ theme }) => theme.components.AppStore.width};
  transform: scale(0.7);
`
