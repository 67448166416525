export const UserAccountContent = {
  resume: {
    headerText: 'Welcome back!',
    subheaderText: `Let’s get back to setting up your account. Confirm that your details are correct so we can get ready to connect to your property.`,
    submitButtonText: 'Confirm',
  },
  confirm: {
    headerText: 'Confirm your details',
    subheaderText: 'Check that your personal information is correct. This should match what your property has on file.',
    submitButtonText: 'Confirm',
  },
}
