import { useCallback, useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { OfferStates, useRestartOnboarding } from '@genoa/middle-end'

import { useAuthState } from '../../../../contexts'
import { useHandleGetOnboardingStatus, useOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { PrefillRedirects, usePrefills } from '../../../../hooks/prefills'
import { useAnalytics, useLogger } from '../../../../providers'
import { useUserAccount } from '../../../../providers'
import { useContent } from '../../../../providers/content'
import { ResumeOnboardingToStep } from './ResumeOnboardingToStep'

type ResumeOnboardingToStepContainerProps = {
  analyticsScreenName: Analytics.Screens
  goToFallback: () => void
  prefillRedirects?: PrefillRedirects
  resumeState?: string
}

export const ResumeOnboardingToStepContainer = (props: ResumeOnboardingToStepContainerProps) => {
  const { content, replaceContent } = useContent()
  const logger = useLogger('ResumeOnboardingToStepContainer')
  const analytics = useAnalytics()
  const { loadingPropertyPrefills } = usePrefills()
  const isResumeExpired = props.resumeState === OfferStates.EXPIRED.toString()

  const { handleResumeOnboarding, onboardingSteps } = useOnboardingStatus(isResumeExpired)
  const { userAccount } = useUserAccount()

  const { handleGetOnboardingStatus } = useHandleGetOnboardingStatus()
  const [{ loading: loadingRestart }, restartOnboarding] = useRestartOnboarding()
  const { user } = useAuthState()

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreenName, { resume_to_step: 'enabled', state: props.resumeState })
  }, [])

  const handleOnResume = async () => {
    analytics.logEvent(Analytics.Events.RESUME_ONBOARDING_RESUME_CTA_CLICKED, { state: props.resumeState })
    if (isResumeExpired && user?.uid) {
      try {
        await restartOnboarding({ customerPublicId: user.uid })
        await handleGetOnboardingStatus(user.uid)
      } catch (error: any) {
        logger.error('Unhandled error during restart expired onboarding', `error: ${error.message}`)
      }
      return
    }
    handleResumeOnboarding(props.goToFallback)
  }

  const handleOnStepClicked = useCallback((step: string) => {
    analytics.logEvent(Analytics.Events.RESUME_STEP_CLICKED, { step })
  }, [])

  const { CTA, HEADER, BODY, BODY_ITS_BEEN_A_WHILE } = content.RESUME_ONBOARDING
  const replacedHeader = replaceContent(HEADER, { name: userAccount.firstName })
  const body = isResumeExpired ? BODY_ITS_BEEN_A_WHILE : BODY

  return (
    <ResumeOnboardingToStep
      onboardingSteps={onboardingSteps.filter((step) => !step.skipDisplay)}
      onResume={handleOnResume}
      customerName={userAccount.firstName || ''}
      loading={loadingPropertyPrefills || loadingRestart}
      onStepClicked={handleOnStepClicked}
      header={replacedHeader}
      body={body ?? ''}
      cta={CTA ?? ''}
    />
  )
}
