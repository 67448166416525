import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../../../../providers'
import { BillingAddress } from '../billing-address-types'
import { BillingAddressDetails } from './BillingAddressDetails'
import { useBillingAddressDetails } from './use-billing-address-details-data'

type BillingAddressDetailsContainerProps = {
  onPressAddCTA: (billingAddress: BillingAddress) => void
  isProcessingRegisterCard: boolean
  billingAddressError: string
  updateBillingNavigate: string
}

export type BillingAddressDetailsContainerNavigationParams = {
  isNavigatingFromUpdateBillingAddress?: boolean
  billingAddressUpdated?: BillingAddress
}

export const BillingAddressDetailsContainer = (props: BillingAddressDetailsContainerProps) => {
  const location = useLocation()
  const [billingAddressInfo, setBillingAddressInfo] = useState<BillingAddress>({
    streetAddress: '',
    name: '',
    city: '',
    state: '',
    zip: '',
  })
  const [isBillingAddressInfoSet, setIsBillignAddressInfo] = useState(false)
  const billingAddressDetails = useBillingAddressDetails()
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const handleSetBillingAddressInfo = useCallback((billingAddress: Partial<BillingAddress>) => {
    setBillingAddressInfo({
      streetAddress: billingAddress?.streetAddress || '',
      name: billingAddress?.name || '',
      city: billingAddress?.city || '',
      state: billingAddress?.state || '',
      zip: billingAddress?.zip || '',
    })
    setIsBillignAddressInfo(true)
  }, [])

  useEffect(() => {
    if (isBillingAddressInfoSet) {
      return
    }

    if (location?.state?.isNavigatingFromUpdateBillingAddress) {
      return handleSetBillingAddressInfo(location.state.billingAddressUpdated || {})
    }
    if (billingAddressDetails.zip) {
      handleSetBillingAddressInfo(billingAddressDetails)
    }
  }, [billingAddressDetails, location.state])

  const handlePressChangeBillingAddress = useCallback(() => {
    analytics.logEvent(Analytics.Events.CHANGE_BILLING_ADDRESS_CLICKED)
    navigate(props.updateBillingNavigate, {
      state: {
        billingAddressInfo,
      },
    })
  }, [billingAddressInfo])

  const handlePressAddCTA = useCallback(() => {
    props.onPressAddCTA(billingAddressInfo)
  }, [props.onPressAddCTA, billingAddressInfo])

  return (
    <BillingAddressDetails
      onPressAddCTA={handlePressAddCTA}
      onPressChangeBillingAddress={handlePressChangeBillingAddress}
      isProcessingRegisterCard={props.isProcessingRegisterCard}
      billingAddress={billingAddressInfo}
      data-dd-action-name="Billing Address"
      billingAddressError={props.billingAddressError}
    />
  )
}
