import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { FirebaseApp, registerVersion } from 'firebase/app'
import { initializeApp } from 'firebase/app'

import { FirebaseConfig } from '../config'

type FirebaseProviderProps = {
  children: ReactNode
}

const FirebaseAppContext = React.createContext<FirebaseApp | undefined>(undefined)

export const useFirebase = () => {
  const app = useContext(FirebaseAppContext)

  if (!app) {
    throw new Error('Cannot call useFirebaseApp unless your component is within a FirebaseAppProvider')
  }

  return app
}

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const [app] = useState(initializeApp(FirebaseConfig))

  useEffect(() => {
    registerVersion('react', React.version || 'unknown')
  }, [])

  return <FirebaseAppContext.Provider value={app}>{children}</FirebaseAppContext.Provider>
}
