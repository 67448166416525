import React from 'react'
import { Analytics } from '@genoa/analytics'
import { capitalizeFirstLetter } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Headline1, InlineButton, PrimaryButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type ConfirmCardProps = {
  analyticsScreenName: Analytics.Screens
  onLinkDifferentCard: () => unknown
  onPressConfirm: () => unknown
  cardLast4Digits: string
  cardNetwork: string
  cardType: string
  isLoading?: boolean
}

export const ConfirmCard = (props: ConfirmCardProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />

      <Headline1>Confirm your card</Headline1>

      <Box minH="24px" />

      <SmallText>
        It&rsquo;s been a while since you added your card. Is this still the card you want to use for your payments to
        flex?
      </SmallText>

      <Box minH="32px" />

      <ConfirmCardDetail flexDirection={'column'}>
        <SmallText fontSize={16}>
          {capitalizeFirstLetter(props.cardType)} card ending in {props.cardLast4Digits}
        </SmallText>
        <Box minH="2px" />
        <SmallText color={theme.colors.grey}>{capitalizeFirstLetter(props.cardNetwork)}</SmallText>
      </ConfirmCardDetail>

      <Box minH="24px" />

      <Flex justifyContent={'center'}>
        <InlineButton onClick={props.onLinkDifferentCard}>
          <SmallTextStyled>
            <b>Change card</b>
          </SmallTextStyled>
        </InlineButton>
      </Flex>
      <Box minH="64px" />
      <PrimaryButton processing={props.isLoading} disabled={props.isLoading} onClick={props.onPressConfirm}>
        Confirm card
      </PrimaryButton>
    </BasePageLayout>
  )
}

const SmallTextStyled = styled(SmallText)`
  font-family: ${(props) => props.theme.fonts.heading};
`

const ConfirmCardDetail = styled(Flex)`
  border-top: 2px solid ${(props) => props.theme.colors.cloud};
  border-bottom: 2px solid ${(props) => props.theme.colors.cloud};
  padding: 16px;
`
