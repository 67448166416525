import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'

import { AppStoreBadge, Congrats as CongratsImage, GooglePlayStoreBadge } from '../../assets'
import { Headline1, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type AlreadySignedUpProps = {
  onOpenAppleStore: () => unknown
  onOpenPlayStore: () => unknown
  analyticsScreenName: Analytics.Screens
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
}

export const AlreadySignedUp = (props: AlreadySignedUpProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
    <Box minH={'15px'} />

    <Flex flexGrow={1} flexShrink={0} flexDirection="column">
      <Flex align="center" justify="center">
        <CongratsImage />
      </Flex>

      <Box minH={'36px'} />

      <Headline1 textAlign="center">Congrats!</Headline1>

      <Box minH={'16px'} />

      <Text textAlign="center">You&rsquo;ve successfully completed setup.</Text>
      <Box minH={'24px'} />
      <Text textAlign="center" fontWeight={'bold'}>
        Download the app to keep up to date with your Flex status.
      </Text>

      <Box minH={'32px'} />

      <Flex
        flexShrink={0}
        direction="column"
        align="center"
        justify="center"
        bg="cloud"
        borderRadius={'8px'}
        px={'15px'}
        py={'30px'}
      >
        <Flex onClick={props.onOpenAppleStore} {...storeBadgeStyles}>
          <AppStoreBadge />
        </Flex>
        <Box minH={'24px'} />
        <Flex onClick={props.onOpenPlayStore} {...storeBadgeStyles}>
          <GooglePlayStoreBadge />
        </Flex>
      </Flex>
    </Flex>

    <Box minH={'30px'} />
  </BasePageLayout>
)
