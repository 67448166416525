import {
  AmountStatus,
  BillerType,
  BillStatus,
  MembershipPeriod,
  RepaymentPeriod,
  ServiceIssueCode,
  Stage,
} from '@genoa/middle-end'
import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import * as Actions from './actions'
import { CustomerStatusState, ResetLoadedPayload } from './types'

export const initialState: CustomerStatusState = {
  customerStatus: {
    bill_transaction_id: '',
    estimation_amount: 0,
    account_status: undefined,
    actual_amount: 0,
    amount_status: AmountStatus.ESTIMATED,
    bill_status: BillStatus.SCHEDULED,
    biller_type: BillerType.DIRECT_INTEGRATION,
    out_of_network: undefined,
    flex_anywhere: undefined,
    service_issue_code: ServiceIssueCode.NO_ISSUE,
    service_issue_description: 'Flex is scheduled to pay your rent bill',
    stage: Stage.GET_READY,
    bill_paid_date: undefined,
    bp_start_date: DateTime.now().toFormat('yyyy-MM-dd'),
    bp_end_date: DateTime.now().toFormat('yyyy-MM-dd'),
    show_next_month_date: DateTime.now().toFormat('yyyy-MM-dd'),
    membership_fee_period: MembershipPeriod.P1M,
    membership_fee_amount: 0,
    repayment_period: RepaymentPeriod.P0D,
    payments: [],
  },
  initialized: false,
  loaded: false,
  accountState: undefined,
  userType: undefined,
  isFlexAnywhere: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetCustomerStatusAction, (_state, action: PayloadAction<ResetLoadedPayload | void>) => {
      const loaded = action.payload ? action.payload.loaded : false
      return { ...initialState, loaded: loaded || false }
    })
    .addCase(Actions.setCustomerStatusAction, (_state, action) => {
      return { ...action.payload }
    })
    .addCase(Actions.setCustomerStatusLoadedAction, (state, action) => {
      return { ...state, loaded: action.payload }
    })
})
