import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { OrchestrationEvaluateOfferResponse, OrchestrationOfferResponse } from '@genoa/middle-end'
import {
  INCOME_COLLECTION_MODAL_CONTENT,
  INFO_COLLECTION_MODAL_CONTENT,
  SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT,
  SOFT_CREDIT_CHECK_LEARN_MORE_MODAL_CONTENT,
  WHY_WE_COLLECT_PERSONAL_INFO_MODAL_CONTENT,
} from '@genoa/screen-content'
import { Box, UnorderedList } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useModal } from '../../../../../contexts'
import { useAnalytics, useFlexLinks } from '../../../../../providers'
import { Gap, SmallText } from '../../../../components'

const isValidResponseData = (data: any): data is OrchestrationOfferResponse => {
  return typeof data === 'object' && 'risk_offer' in data
}

export const useSoftCreditCheckModal = () => {
  const modal = useModal()
  const theme = useTheme()
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const getErrorModalContent = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    if (status === 422 && !isValidResponseData(data) && data?.message) {
      return {
        children: <SmallText>{data.message}</SmallText>,
        title: 'Something went wrong',
      }
    } else if (status === 400) {
      return {
        children: <SmallText>{SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.DOB_AND_SOCIAL}</SmallText>,
        title: SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.INFO_MISMATCH,
      }
    }
    return {
      children: (
        <SmallText>
          {SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.UNABLE_TO_PROCESS}
          <SmallText
            onClick={() => flexLinks.open(FlexLinks.supportTicketWeb)}
            color={theme.colors.brand[200]}
            fontWeight="bold"
          >
            {SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.REACH_OUT}
          </SmallText>{' '}
          <SmallText>{SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.FOR_ASSISTANCE}</SmallText>
        </SmallText>
      ),
      title: SOFT_CREDIT_CHECK_ERROR_MODAL_CONTENT.SOMETHING_WENT_WRONG,
    }
  }

  const openErrorModal = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_ERROR_MODAL)
    const errorModalContent = getErrorModalContent(status, data)
    modal.show({
      title: errorModalContent.title,
      cta: 'Confirm',
      render: () => errorModalContent.children,
    })
  }

  const openIncomeCollectionModal = () => {
    modal.show({
      title: INCOME_COLLECTION_MODAL_CONTENT.TITLE,
      render: () => (
        <Box textAlign="left" justifyItems="start">
          <SmallText>{INCOME_COLLECTION_MODAL_CONTENT.TOP_BODY}</SmallText>
          <UnorderedList py={5}>
            <SmallText align="left">
              {INCOME_COLLECTION_MODAL_CONTENT.BULLETS.map((v, i) => (
                <li key={i}>{v}</li>
              ))}
            </SmallText>
          </UnorderedList>
          <SmallText>{INCOME_COLLECTION_MODAL_CONTENT.FOOTER_BODY}</SmallText>
        </Box>
      ),
      cta: INCOME_COLLECTION_MODAL_CONTENT.CTA,
    })
  }

  const openInfoCollectionModal = () => {
    modal.show({
      title: INFO_COLLECTION_MODAL_CONTENT.TITLE,
      render: () => <SmallText>{INFO_COLLECTION_MODAL_CONTENT.BODY}</SmallText>,
      cta: INFO_COLLECTION_MODAL_CONTENT.CTA,
    })
  }

  const openLearnMoreModal = () => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_LEARN_MORE_CLICKED)

    modal.show({
      title: SOFT_CREDIT_CHECK_LEARN_MORE_MODAL_CONTENT.TITLE,
      cta: SOFT_CREDIT_CHECK_LEARN_MORE_MODAL_CONTENT.CTA,
      render: () => {
        return (
          <>
            <SmallText>{SOFT_CREDIT_CHECK_LEARN_MORE_MODAL_CONTENT.MAIN_TEXT_1}</SmallText>
            <Gap size="spacing_150" />
            <SmallText>{SOFT_CREDIT_CHECK_LEARN_MORE_MODAL_CONTENT.MAIN_TEXT_2}</SmallText>
          </>
        )
      },
    })
  }

  const openWhyWeCollectInfoModal = () => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_WHY_COLLECT_CLICKED)

    modal.show({
      title: WHY_WE_COLLECT_PERSONAL_INFO_MODAL_CONTENT.TITLE,
      cta: 'Close',
      render: () => {
        return (
          <Box>
            <SmallText>{WHY_WE_COLLECT_PERSONAL_INFO_MODAL_CONTENT.MAIN_TEXT_1}</SmallText>
            <Gap size="spacing_100" />
            <SmallText>{WHY_WE_COLLECT_PERSONAL_INFO_MODAL_CONTENT.MAIN_TEXT_2}</SmallText>
          </Box>
        )
      },
    })
  }

  return {
    openErrorModal,
    openIncomeCollectionModal,
    openInfoCollectionModal,
    openLearnMoreModal,
    openWhyWeCollectInfoModal,
  }
}
