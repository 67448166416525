import React from 'react'
import { Analytics } from '@genoa/analytics'
import { FLEX_ANYWHERE_HOW_YOU_PAY_CONTENT as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { FlexAnywhereLoadCard, FlexAnywherePayProperty } from '../../../assets'
import { ExtraSmallText, Headline1, InlineButton, PrimaryButton, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type FlexAnywhereHowYouPayProps = {
  onClickGotIt: () => void
  onCantPayViaDebit: () => void
  analyticsScreenName: Analytics.Screens
  loading: boolean
}

const FlexAnywhereHowYouPay = (props: FlexAnywhereHowYouPayProps) => {
  const theme = useTheme()
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />
      <Flex direction="column">
        <Headline1>{content.HEADER}</Headline1>
        <Box minH="15px" />
        <SmallText>{content.MAIN_TEXT}</SmallText>
        <Box minH="30px" />
        <HowYouPayInfoBoxContainer>
          <ContentContainer>
            <FlexAnywhereLoadCard height="auto" width="100%" />
            <Box minH="12px" />

            <TextContainer>
              <SmallText textAlign="left">{content.BODY_1}</SmallText>
            </TextContainer>
          </ContentContainer>
          <Box minH="12px" />

          <ContentContainer>
            <FlexAnywherePayProperty height="auto" width="100%" />
            <Box minH="12px" />

            <TextContainer>
              <SmallText textAlign="left">{content.BODY_2}</SmallText>
            </TextContainer>
          </ContentContainer>
        </HowYouPayInfoBoxContainer>
        <Box minH="8px" />

        <ExtraSmallText color={theme.colors.dusk}>{content.DISCLAIMER}</ExtraSmallText>
        <Box minH="48px" />

        <Box>
          <PrimaryButton
            onClick={props.onClickGotIt}
            testID="ContinueButton"
            disabled={props.loading}
            processing={props.loading}
          >
            Got it
          </PrimaryButton>
        </Box>
      </Flex>
    </BasePageLayout>
  )
}

export default FlexAnywhereHowYouPay

const HowYouPayInfoBoxContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.fixedSizes.md};
  padding: ${(props) => props.theme.fixedSizes.md};
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const TextContainer = styled(Box)`
  width: 100%;
  align-items: flex-start;
`
