// import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

// import * as Routes from '../../../../routing/constants'
import { SLCAutopaySetupContainer } from '../../common/slc/autopay-setup'

export const OnboardingSLCAutopaySetupContainer = () => {
  // const navigate = useNavigate()

  return <SLCAutopaySetupContainer analyticsScreenName={Analytics.Screens.ONBOARDING_AUTOPAY_SETUP} onNext={() => {}} />
}
