import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { PhoneOnboardingExperiment } from '@genoa/experiments'
import { useFindUserAccount } from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'

import { useModal } from '../../../contexts'
import { useExperimentVariant, useReduxAction, useReduxSelector, useShowErrorMessageModal } from '../../../hooks'
import { RootState } from '../../../modules'
import { useOnboardingState } from '../../../modules/flex2/use-state'
import {
  ContactState,
  PhoneNumberState,
  setConsolidatedExperienceAction,
  setContactAction,
  setPhoneNumberAction,
} from '../../../modules/onboarding'
import { useNavigation } from '../../../navigation'
import { useLogger } from '../../../providers'
import { useAnalytics } from '../../../providers/analytics'
import * as Routes from '../../../routing/constants'
import { SmallText } from '../../components'
import { PhoneConsolidation } from './PhoneConsolidation'

export const PhoneConsolidationContainer = () => {
  const logger = useLogger('PhoneConsolidationContainer')
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const experimentVariant = useExperimentVariant(PhoneOnboardingExperiment)
  const onboardingState = useOnboardingState()
  const setPhoneNumber = useReduxAction(setPhoneNumberAction)
  const setContact = useReduxAction(setContactAction)
  const setConsolidatedExperience = useReduxAction(setConsolidatedExperienceAction)

  const phoneNumber = useReduxSelector((state: RootState) => state.onboarding.phoneNumber)
  const contact = useReduxSelector((state: RootState) => state.onboarding.contact)
  const [errors, setErrors] = useState({})
  const { navigateToLogIn } = useNavigation()
  const { showErrorMessage } = useShowErrorMessageModal()
  const modal = useModal()
  const [{ loading: loadingFindAccount }, findUserAccount] = useFindUserAccount()

  const handleError = () => {
    showErrorMessage(
      'Something went wrong',
      'We couldn’t process your request. Please try again or contact support if the problem persists',
      'Try again'
    )
  }

  const handleNext = useCallback(
    async (contact: ContactState) => {
      if (!contact.email || !contact.phoneNumber) {
        return
      }
      analytics.logEvent(Analytics.Events.SIGNIN_PHONE_NUMBER_CTA_CLICKED, { consolidated: true })
      try {
        const response = await findUserAccount({ email: contact.email, phoneNumber: `1${phoneNumber.extracted}` })
        if (response.status === 200) {
          analytics.logEvent(Analytics.Events.ALREADY_HAVE_ACCOUNT_MODAL_SHOWN)
          modal.show({
            title: 'We found an account with that phone number or email address',
            cta: 'Log in',
            secondaryAction: 'Go back',
            render: () => <SmallText>You can try logging in or signing up with different credentials</SmallText>,
            onCTAclicked: () => {
              analytics.logEvent(Analytics.Events.ALREADY_HAVE_ACCOUNT_MODAL_LOGIN_CLICKED)
              navigateToLogIn()
            },
          })
        } else if (response.status === 404) {
          setConsolidatedExperience(true)
          navigate(Routes.Auth.PIN)
        } else {
          logger.error('findUserAccount', `Unexpected status ${response.status}`)
          handleError()
        }
      } catch (error: any) {
        logger.error('findUserAccount', `Unhandled exception ${error?.message}`)
        handleError()
      }
    },
    [analytics.logEvent, phoneNumber.extracted]
  )

  const coBrandingProperty = useMemo(() => {
    if (onboardingState.coBrandingProperty !== undefined) {
      // track if the property name is available in the url but has no value
      analytics.logEvent(Analytics.Events.CO_BRANDING_PROPERTY_DISPLAYED, {
        has_empty_value: onboardingState.coBrandingProperty === '',
      })
    }
    return onboardingState.coBrandingProperty || ''
  }, [onboardingState.coBrandingProperty])

  const handleSetPhoneNumber = (phoneNumber: PhoneNumberState) => {
    setPhoneNumber(phoneNumber)
    if (phoneNumber.extracted?.length !== 10) {
      setErrors({ phoneNumber: 'Must be a valid phone number' })
    } else {
      setErrors({ phoneNumber: '' })
    }
  }

  const handleAlreadyHaveAnAccount = () => {
    analytics.logEvent(Analytics.Events.SIGNIN_ALREADY_HAVE_AN_ACCOUNT_CLICKED)
    navigateToLogIn()
  }

  const handleFieldChange = (field: string, value: string) => {
    if (!contact?.hasOwnProperty(field)) {
      analytics.logEvent(Analytics.Events.SIGNUP_ACCOUNT_CREATION_FIELD_EDIT, { field })
    }
    setContact({ ...contact, [field]: value })
  }

  return (
    <Box>
      <PhoneConsolidation
        phone={phoneNumber.extracted}
        onPhoneChange={handleSetPhoneNumber}
        onFieldChange={handleFieldChange}
        onNextClick={handleNext}
        modifyOpener={experimentVariant === PhoneOnboardingExperiment.variantValues.Opener}
        modifySubtext={experimentVariant === PhoneOnboardingExperiment.variantValues.Subtext}
        coBrandingProperty={coBrandingProperty}
        errors={errors}
        contact={contact}
        onAlreadyHaveAnAccount={handleAlreadyHaveAnAccount}
        isLoading={loadingFindAccount}
      />
    </Box>
  )
}
