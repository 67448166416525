import { Analytics } from '@genoa/analytics'
import { EMBED_SOMETHING_WENT_WRONG as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSpill } from '../../../../../assets'
import { AppStore, Gap, Headline2, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedSomethingWentWrong = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_SOMETHING_WENT_WRONG}>
    <Container>
      <Gap size="spacing_200" />
      <HeroSpill />
      <Gap size="spacing_200" />
      <Headline2>{content.HEADER}</Headline2>
      <Gap size="spacing_100" />
      <SmallText>{content.BODY_1}</SmallText>
      <Gap size="spacing_100" />
      <SmallText fontWeight="bold">{content.BODY_2}</SmallText>
      <Gap size="spacing_200" />
      <AppStore />
    </Container>
  </BasePageLayout>
)
const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
