import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useFlexLinks } from '../../providers'
import { GooglePlayStoreBadge } from '../assets'

export function PlayStoreButton(props: React.SVGProps<SVGSVGElement>) {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  function handleOpenPlayStore() {
    analytics.logEvent(Analytics.Events.CONGRATS_OPEN_PLAY_STORE_CLICKED)
    flexLinks.open(FlexLinks.googlePlayStore)
  }

  return <GooglePlayStoreBadge {...props} role="link" tabIndex={0} onClick={handleOpenPlayStore} />
}
