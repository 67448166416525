import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, EMBED_NOT_RENT_DAY } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroCalendar } from '../../../../../assets'
import { AppStore, Gap, Headline2, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedNotRentDay = () => {
  {
    /* TODO: Replace date literal with correct date from backend */
  }
  const content = deepReplaceContent(EMBED_NOT_RENT_DAY, {
    bpMonth: 'July',
    bpDate: 'June 29th',
  })
  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_NOT_RENT_DAY}>
      <Container>
        <Gap size="spacing_200" />
        <HeroCalendar />
        <Gap size="spacing_200" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />
        <SmallText textAlign="center">
          {content.BODY_1}
          <SmallText fontWeight={'bold'}>{content.BP_DATE}</SmallText>
          {content.BODY_2}
        </SmallText>
        <Gap size="spacing_200" />
        <SmallText textAlign="center">
          {content.BODY_3}
          <SmallText fontWeight="bold"> {content.NOTICE}</SmallText>
        </SmallText>
        <Gap size="spacing_200" />
        <AppStore />
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
