import { CREDIT_BUILDER_CONFIRM_SCHEDULE_CONTENT, deepReplaceContent } from '@genoa/screen-content'
import { ordinalSuffixFor } from '@genoa/utils'
import { Checkbox, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  AddChangeCardPaymentMethod,
  ExtraSmallText,
  Frame,
  Headline2,
  PaymentSchedule,
  PaymentScheduleItemProps,
  PrimaryButton,
  SmallText,
} from '../../../../components'
import { CardDetails } from '../../../../components/PaymentMethods/common'

export interface CreditBuilderConfirmScheduleProps {
  readonly payments: readonly PaymentScheduleItemProps[]
  readonly cardDetails: CardDetails | undefined
  readonly continueDisabled: boolean
  readonly paymentMonth: string
  readonly accepted: boolean
  readonly repaymentDay: number
  readonly showDisclaimer: boolean
  readonly onAddCard: () => void
  readonly onChangeCard: () => void
  readonly onToggleAccept: (value: boolean) => void
  readonly onContinue: () => void
}

export const CreditBuilderConfirmSchedule = ({
  payments,
  cardDetails,
  continueDisabled,
  paymentMonth,
  accepted,
  repaymentDay,
  showDisclaimer,
  onAddCard,
  onChangeCard,
  onToggleAccept,
  onContinue,
}: CreditBuilderConfirmScheduleProps) => {
  const theme = useTheme()

  const content = deepReplaceContent(CREDIT_BUILDER_CONFIRM_SCHEDULE_CONTENT, {
    month: paymentMonth,
    repaymentOrdinal: ordinalSuffixFor(repaymentDay),
  })

  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
        <SmallText>{content.SUB_HEADER}</SmallText>
      </Stack>
      <PaymentSchedule payments={payments} title={content.SCHEDULE.TITLE} showReloadIcon>
        {showDisclaimer && (
          <FirstPaymentDisclaimerFrame>
            <ExtraSmallText>{content.SCHEDULE.BP_DISCLAIMER}</ExtraSmallText>
          </FirstPaymentDisclaimerFrame>
        )}
      </PaymentSchedule>
      <PaymentMethodStack>
        <PaymentMethodTitle color={theme.colors.dusk}>{content.PAYMENT_METHOD.TITLE}</PaymentMethodTitle>
        <AddChangeCardPaymentMethod cardDetails={cardDetails} onAddCard={onAddCard} onChangeCard={onChangeCard} />
        <CheckboxContainer>
          <StyledCheckBox isChecked={accepted} onChange={() => onToggleAccept(!accepted)} />
          <SmallText>{content.AUTHORIZATION}</SmallText>
        </CheckboxContainer>
      </PaymentMethodStack>
      <Stack>
        <PrimaryButton disabled={continueDisabled} onClick={onContinue}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const PaymentMethodStack = styled(Stack)`
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const PaymentMethodTitle = styled(SmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

const FirstPaymentDisclaimerFrame = styled(Frame)`
  background-color: ${(props) => props.theme.colors.cloud};
`

const CheckboxContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const StyledCheckBox = styled(Checkbox)`
  > span {
    margin-top: 0px;
  }
`
