import { Analytics } from '@genoa/analytics'
import { EMBED_BUILD_CREDIT_IN_APP as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroPhone } from '../../../../../assets'
import { AppStore, Gap, Headline2, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedBuildCreditInApp = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_BUILD_CREDIT_IN_APP}>
    <Container>
      <Gap size="spacing_200" />
      <HeroPhone />
      <Gap size="spacing_200" />
      <Headline2>{content.HEADER}</Headline2>
      <Gap size="spacing_100" />
      <SmallText textAlign="center">{content.BODY_1}</SmallText>
      <Gap size="spacing_100" />
      <SmallText>{content.BODY_2}</SmallText>
      <Gap size="spacing_200" />
      <AppStore />
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
