import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_VALUE_PROP as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CheckmarkCirclePurple, CreditScoreIncreaseIcon } from '../../../../assets'
import { ExtraSmallText, Gap, Headline2, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface CreditBuilderValuePropProps {
  onClickLearnMore: () => void
  onClickContinue: () => void
  loadingSubmit: boolean
  analyticsScreenName: Analytics.Screens
}

export const CreditBuilderValueProp = (props: CreditBuilderValuePropProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <MainContentStack direction="column">
        <Gap size="spacing_100" />
        <IconWrapper display="flex" justifyContent="center">
          <CreditScoreIncreaseIcon />
        </IconWrapper>
        <Gap size="spacing_100" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_250" />
        <ItemListContainer display="flex" flexDirection="column">
          {content.BULLETS.map((item, index) => (
            <ListItemWrapper key={`credit-builder-value-prop-item-${index + 1}`} justifyContent={'spaceBetween'}>
              <ListItem>
                <CheckmarkCirclePurple />
                <ItemTextWrapper flexWrap="wrap" flex="1">
                  <ItemText fontWeight={item.BOLD ? 'bold' : undefined}>{item.BODY}</ItemText>
                </ItemTextWrapper>
              </ListItem>
            </ListItemWrapper>
          ))}
        </ItemListContainer>
        <Gap size="spacing_100" />
        <DisclaimerText>
          <DisclaimerText color={'dusk'}>{content.DISCLAIMER} </DisclaimerText>
          <LearnMoreLink color={'jewelPurple'} fontWeight={'bold'} onClick={props.onClickLearnMore}>
            {content.DISCLAIMER_LINK}
          </LearnMoreLink>
        </DisclaimerText>
        <Gap size="spacing_100" />
        <DisclaimerText color={'dusk'}>{content.DISCLAIMER_TWO}</DisclaimerText>
      </MainContentStack>
      <Gap size="spacing_200" />
      <PrimaryButton
        processing={props.loadingSubmit}
        disabled={props.loadingSubmit}
        onClick={props.onClickContinue}
        testID="CreditBuilderValuePropNextButton"
      >
        {content.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const IconWrapper = styled(Box)`
  align-items: center;
`

const MainContentStack = styled(Flex)`
  width: 100%;
`

const ItemListContainer = styled(Box)`
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  padding-bottom: 0;
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
`

const ListItemWrapper = styled(Box)`
  display: flex;
  padding-bottom: ${(props) => props.theme.fixedSizes.spacing_100};
`

const ListItem = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: flex-start;
`

const DisclaimerText = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

const ItemTextWrapper = styled(Box)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`

const ItemText = styled(SmallText)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_100};
  flex-wrap: wrap;
`

const LearnMoreLink = styled(ExtraSmallText)`
  cursor: pointer;
`
