import { usePaymentStatusHookFactory } from '@genoa/state-management/lib/modules/api/payment'

import { useAuthState } from '../../contexts'
import { useGetPaymentStatusQuery } from '../../modules/flexApi'
import { useSecureLineOfCredit } from '../secure-line-of-credit'

export const usePaymentStatus = () => {
  const { slcType } = useSecureLineOfCredit()
  return usePaymentStatusHookFactory(useGetPaymentStatusQuery, useAuthState, slcType)
}
