import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { LinkText, SmallText } from '../../../../components'

interface PrefillsConfirmUnitBannerProps {
  readonly onReset: () => void
  readonly propertyName: string
}

const StartOverLinkText = (props: Required<PrefillsConfirmUnitBannerProps>) => {
  return (
    <LinkText fontSize="16px" textAlign="left" onClick={props.onReset}>
      <b>Start over with a new address</b>
    </LinkText>
  )
}

export const PrefillConfirmUnitBanner = (props: PrefillsConfirmUnitBannerProps) => {
  return (
    <PrefillsBanner flexShrink={0} direction="column" bg="cloud">
      <SmallText fontSize="16px" textAlign="left" alignSelf="stretch">
        Hurray! Because your property is a Flex partner, we were able to see that you live at{' '}
        <SmallText fontSize="16px" fontWeight="bold">
          {props.propertyName}
        </SmallText>
        .
      </SmallText>
      <Box>
        Not right? <StartOverLinkText {...props} />
      </Box>
    </PrefillsBanner>
  )
}

const PrefillsBanner = styled(Flex)`
  padding: 16px;
  gap: 12px;
  border-radius: 16px;
  bg: cloud;
`
