import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { SLCHowFlexWorksContainer } from '../../common/slc/how-flex-works'

export const OnboardingSLCHowFlexWorksContainer = () => {
  const navigate = useNavigate()

  return (
    <SLCHowFlexWorksContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_HOW_FLEX_WORKS}
      onNext={() => navigate(Routes.Onboarding.HOW_TO_PAY_FLEX_BACK)}
    />
  )
}
