import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, SLC_CLASSIC_HOW_FLEX_WORKS } from '@genoa/screen-content'
import { Box, Flex, VStack } from '@chakra-ui/react'

import { useAnalytics } from '../../../../../providers/analytics'
import theme from '../../../../../theme/theme'
import { SLCHowFlexWorks as SLCHowFlexWorksImage } from '../../../../assets'
import { GuidedList, Headline2 } from '../../../../components'

export interface SLCHowFlexWorksProps {
  deposit: string
  rent: string

  baseAmount: string
  processingPercent: string
}

export const SLCClassicHowFlexWorks = ({ baseAmount, deposit, processingPercent, rent }: SLCHowFlexWorksProps) => {
  const analytics = useAnalytics()
  const content = deepReplaceContent(SLC_CLASSIC_HOW_FLEX_WORKS, {
    baseAmount,
    deposit,
    processingPercent,
    rent,
  })

  useEffect(() => {
    analytics.logScreenView(Analytics.Screens.SLC_CLASSIC_HOW_FLEX_WORKS)
  }, [])

  return (
    <Flex direction="column">
      <VStack margin={theme.fixedSizes.spacing_50} spacing={theme.fixedSizes.spacing_100}>
        <SLCHowFlexWorksImage width="auto" height="150px" />
        <Headline2 textAlign="center">{content.HEADING}</Headline2>
        <p>{content.SUBTITLE}</p>
        <Box textAlign="left">
          <GuidedList items={content.BULLETS.map(({ HEADER, BODY }) => ({ label: HEADER, content: BODY }))} />
        </Box>
      </VStack>
    </Flex>
  )
}
