import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'

import { HeroChecklist as NotApprovedImage } from '../../assets'
import { Headline1, InlineButton, PrimaryButton, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type NotApprovedProps = {
  onMessageSupport: () => unknown
}

const imageSize = {
  maxHeight: '280px',
  minHeight: '200px',
}

export const NotApproved = (props: NotApprovedProps) => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_NOT_APPROVED}>
    <Flex direction="column" align="center">
      <Box minH={'60px'} />

      <Flex {...imageSize}>
        <NotApprovedImage />
      </Flex>

      <Headline1 textAlign="center">We can&rsquo;t offer you Flexible Rent at this time</Headline1>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'}>
        Check your email for more details about your application decision.
      </Text>

      <Box minH={'24px'} />

      <Flex>
        <InlineButton onClick={props.onMessageSupport}>
          <b>Learn more</b>
        </InlineButton>
      </Flex>
    </Flex>
  </BasePageLayout>
)
