import React, { useCallback, useRef } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyDetails, BillerPropertyUnit } from '@genoa/domain'
import { checkIsUnitMatched } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Headline2, PrimaryButton, SmallText, TextInput } from '../../../../components'
import { ScrollableSelectionList } from '../../../../components/SelectionList/ScrollableSelectionList'
import { BasePageLayout } from '../../../../layouts'
import {
  DirectIntegrationUnitSelectionListItem,
  UnitSelectionListSkeletonItems,
} from './DirectIntegrationUnitSelectionListItem'
import { NoneOfTheAbove } from './NoneOfTheAbove'
import { PrefillConfirmUnitBanner } from './PrefillConfirmUnitBanner'

interface DirectIntegrationUnitSelectionProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly property: BillerPropertyDetails
  readonly units?: BillerPropertyUnit[]
  readonly unitsInitialized: boolean
  readonly selectedUnit?: BillerPropertyUnit
  readonly query: string
  readonly connectingBiller?: boolean
  readonly onQuery: (input: string) => unknown
  readonly onSelect: (unit: BillerPropertyUnit) => boolean
  readonly onConfirm: () => void
  readonly onNoneOfTheAbove: () => unknown
  readonly onReset: () => void
  readonly showPrefills: boolean
}

export const DirectIntegrationUnitSelection = (props: DirectIntegrationUnitSelectionProps) => {
  const theme = useTheme()

  const confirmRef = useRef<HTMLDivElement>(null)
  const onSelect = useCallback(
    (item: BillerPropertyUnit) => {
      const unitSelected = props.onSelect(item)
      if (confirmRef.current && unitSelected) {
        confirmRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    },
    [confirmRef, props.onSelect]
  )

  return (
    <BasePageLayout
      analyticsScreenName={props.analyticsScreenName}
      analyticsScreenParams={{ property: props.property }}
    >
      <Container>
        <Box marginTop="30px">
          <Box px="lg">
            <Headline2>Which unit do you live in?</Headline2>
          </Box>
          <Box minH="15px" />

          <ConfirmUnitText
            showPrefills={props.showPrefills}
            onReset={props.onReset}
            propertyName={props.property.name}
          />

          <Box minH="10px" />
          <SmallText fontWeight="bold">Apartment, suite, or unit #</SmallText>
          <Box minH="5px" />
          <TextInput
            text={props.query}
            onTextChange={props.onQuery}
            testID="DirectIntegrationUnitSelectionFilterInput"
          />
        </Box>

        <ScrollableSelectionList>
          {props.units ? (
            props.units.map((item, index) => (
              <DirectIntegrationUnitSelectionListItem
                onClick={() => onSelect(item)}
                key={`unit-${index}`}
                theme={theme}
                index={index}
                unit={item}
                isSelected={checkIsUnitMatched(item, props.selectedUnit)}
              />
            ))
          ) : (
            <UnitSelectionListSkeletonItems />
          )}
        </ScrollableSelectionList>
        <Box marginTop="12px" />
        <NoneOfTheAbove onPress={props.onNoneOfTheAbove} theme={theme} />
        <Box minH="12px" />
        <PrimaryButton
          testID="ConfirmButton"
          onClick={props.onConfirm}
          disabled={props.selectedUnit === undefined || props.connectingBiller}
          processing={props.connectingBiller}
        >
          Confirm
        </PrimaryButton>
        <Box ref={confirmRef} />
      </Container>
    </BasePageLayout>
  )
}

interface ConfirmUnitText {
  readonly propertyName: string
  readonly onReset: () => void
  readonly showPrefills: boolean
}

const ConfirmUnitText = (props: ConfirmUnitText) => {
  if (props.showPrefills) {
    return <PrefillConfirmUnitBanner onReset={props.onReset} propertyName={props.propertyName} />
  }

  return (
    <SmallText>
      Your property <SmallText fontWeight="bold">{props.propertyName}</SmallText> has multiple units associated with it.
    </SmallText>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`
