import { Route } from 'react-router-dom'

import {
  EmbedAlreadyFlexingYourRent,
  EmbedBuildCreditInApp,
  EmbedCongrats,
  EmbedNotRentDay,
  EmbedRentSplitProgress,
  EmbedServiceIssue,
  EmbedSomethingWentWrong,
  ManualPayFinishContainer,
  SimplifiedEpisodicContainer,
} from '../../views/pages/common/embed'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

export const EMBED_ROUTES = (
  <>
    <Route path={routeSlugs.CONGRATS} element={<EmbedCongrats />} />

    <Route path={routeSlugs.SIMPLIFIED_EPISODIC} element={<SimplifiedEpisodicContainer />} />
    <Route path={routeSlugs.MANUAL_PAY_FINISH} element={<ManualPayFinishContainer />} />

    <Route path={routeSlugs.ALREADY_FLEXING_YOUR_RENT} element={<EmbedAlreadyFlexingYourRent />} />
    <Route path={routeSlugs.BUILD_CREDIT_IN_APP} element={<EmbedBuildCreditInApp />} />
    <Route path={routeSlugs.NOT_RENT_DAY} element={<EmbedNotRentDay />} />
    <Route path={routeSlugs.RENT_SPLIT_PROGRESS} element={<EmbedRentSplitProgress />} />
    <Route path={routeSlugs.SERVICE_ISSUE} element={<EmbedServiceIssue />} />
    <Route path={routeSlugs.SOMETHING_WENT_WRONG} element={<EmbedSomethingWentWrong />} />
  </>
)
