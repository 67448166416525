import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { CheckboxLaptop } from '../../assets'
import { Text } from '../../components'

export const ExperimentPhoneOpener = () => {
  return (
    <>
      <Flex
        flexShrink={0}
        direction="column"
        align="center"
        justify="center"
        bg="cloud"
        padding="16px"
        gap="8px"
        borderRadius={'16px'}
      >
        <Flex align="center" justify="center">
          <CheckboxLaptop />
        </Flex>
        <Box minH={'16px'} />
        <Text textAlign="center" fontSize={20} fontWeight={700} alignSelf="stretch" lineHeight="30px">
          Welcome to worry-free rent.
        </Text>
      </Flex>
      <Box minH={'32px'} />
    </>
  )
}
