import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../../../providers/content'
import { ExtraSmallText, Headline2, PrimaryButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type ConnectCardProps = {
  analyticsScreenName: Analytics.Screens
  onClickAddCardDetails: () => unknown
  processingFeePercentage: number
  debitProcessingFee: number
  creditProcessingFee: number
}

export const V2ConnectCard = (props: ConnectCardProps) => {
  const {
    replaceContent,
    content: { CONNECT_CARD },
  } = useContent()

  const bodyLine2 = replaceContent(CONNECT_CARD.BODY_LINE_2, {
    debitProcessingFee: props.debitProcessingFee,
    creditProcessingFee: props.creditProcessingFee,
  })

  const bodyLine3 = replaceContent(CONNECT_CARD.BODY_LINE_3, {
    creditProcessingFee: props.creditProcessingFee,
  })

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" px="lg" />
      <Container>
        <Headline2>{CONNECT_CARD.HEADER}</Headline2>
        <Box minH="30px" px="lg" />

        <Box data-testid="CFPInformation">
          <SmallText>{CONNECT_CARD.BODY_LINE_1}</SmallText>
          <Box minH="20px" px="lg" />
          <SmallText>{bodyLine2}</SmallText>
          <Box minH="20px" px="lg" />
          <SmallText>{bodyLine3}</SmallText>
          <Box minH="30px" px="lg" />
        </Box>
        <Box minH="30px" px="lg" />
        <PrimaryButton
          processing={false}
          disabled={false}
          onClick={props.onClickAddCardDetails}
          testID="ConnectCardAddCardDetailsButton"
        >
          {CONNECT_CARD.CTA}
        </PrimaryButton>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-right: 9px;
`

const DisclaimerText = styled(ExtraSmallText)`
  padding-top: 2px;
`
