import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { GetDefaultCardResponse, useGetCardsV2, useGetDefaultCard } from '@genoa/middle-end'
import { AxiosPromise } from 'axios'

import { useAuthState } from '../../../contexts'
import { useAmplitudeFeatureFlag } from '../../../hooks/use-amplitude-feature-flag'
import { resetCustomerCardAction, setCustomerCardAction } from '../../../modules/flex2/customer-wallet'
import { useCustomerWalletState } from '../../../modules/flex2/use-state'
import { useLogger } from '../../../providers'
import { useReduxAction } from '../../use-redux-action'

type UseGetCardsParams = {
  componentName: string
  setCardNetwork?: (network: string) => void
  setCardLast4Digits?: (last4OfCard: string) => void
  setCardType?: (cardType: string) => void
  capitalizeCardType?: boolean
}

export const useGetCards = (params: UseGetCardsParams) => {
  const componentName = `useGetCardsV2 - ${params.componentName}`
  const loggerV2 = useLogger(componentName)
  const { uid } = useAuthState()
  const [{ loading: loadingDefaultCardV2 }, getCardsV2] = useGetCardsV2()
  const [{ loading: loadingDefaultCardV1 }, getCard] = useGetDefaultCard()
  const setWalletState = useReduxAction(setCustomerCardAction)

  const customerWalletState = useCustomerWalletState()
  const resetCustomerCard = useReduxAction<void>(resetCustomerCardAction)
  const { enabled: getCardsV2Enabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.UseGetCardsV2)

  const getDefaultCardV2 = async (customerPublicId: string) => {
    if (!customerPublicId) {
      loggerV2.error('getCardsV2', 'getCardsV2 called without a customerPublicId')
    }

    try {
      const response = await getCardsV2({ customerPublicId })
      if (response.status !== 200 || !response.data.ok) {
        loggerV2.error(`${componentName}`, 'Unexpected status', { status: response.status })
        resetCustomerCard()
      } else {
        if (response.data.ok && response.data.data) {
          setWalletState({ defaultCard: response.data.data.default })
          return response
        }
      }
    } catch (error: any) {
      loggerV2.error('getCardsV2', `error: ${error?.message}`)
    }
  }

  const getDefaultCardV1 = (customerId: string): AxiosPromise<GetDefaultCardResponse> => {
    if (!customerId) {
      loggerV2.error('getDefaultCard', 'getDefaultCard called without a customerId')
      return Promise.resolve({}) as AxiosPromise<GetDefaultCardResponse>
    }
    return getCard({ customerId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          // TODO: Change in mobile not carried over to web yet
          // setWalletState({
          //   defaultCard: {
          //     ...response.data.card,
          //     fingerprint: '',
          //     issuer: '',
          //     is_stripe_connect_setup: false,
          //     card_type: response.data.card.card_type ?? '',
          //   },
          // })
          setWalletState({
            defaultCard: {
              ...response.data.card,
              card_type: response.data.card.card_type ?? '',
            },
          })
        } else if (response.status !== 404) {
          loggerV2.error('getCardsV1', 'unexpected status', { status: response.status })
          resetCustomerCard()
        }
        return response
      })
      .catch((error: any) => {
        loggerV2.error('getCardsV1', `error: ${error?.message}`)
        return error
      })
  }

  const getDefaultCard = getCardsV2Enabled ? getDefaultCardV2 : getDefaultCardV1

  const updateWalletState = async () => {
    if (!customerWalletState.initialized) {
      if (uid) {
        // If the default card is missing on state try fetching it
        await getDefaultCard(uid)
      } else {
        loggerV2.error(
          `${params.componentName} - initialize default card`,
          'Default card is missing on payment method settings'
        )
        return
      }
    }
    const defaultCard = customerWalletState.defaultCard
    params.setCardNetwork && params.setCardNetwork(defaultCard.brand)
    params.setCardLast4Digits && params.setCardLast4Digits(defaultCard.card_mask)

    if (params.capitalizeCardType && params.setCardType) {
      let tempCardType =
        defaultCard.card_type && defaultCard.card_type.charAt(0).toUpperCase() + defaultCard.card_type.slice(1)
      tempCardType && params.setCardType(tempCardType)
    } else {
      defaultCard.card_type && params.setCardType && params.setCardType(defaultCard.card_type)
    }
  }

  const loadingDefaultCard = getCardsV2Enabled ? loadingDefaultCardV2 : loadingDefaultCardV1

  return {
    getDefaultCard,
    getDefaultCardV1,
    getDefaultCardV2,
    loadingDefaultCard,
    loadingDefaultCardV1,
    updateWalletState,
  }
}
