import { Agreement } from '@genoa/domain'
import { Box, Checkbox } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { AgreementsList } from './AgreementsList'

type AcceptanceOfferAuthorizeProps = {
  toggleAcceptTerms: () => unknown
  termsAccepted: boolean
  fetchedAgreements?: Agreement[]
  agreeText?: string
}

export const AcceptanceOfferAuthorize = (props: AcceptanceOfferAuthorizeProps) => {
  const theme = useTheme()

  return (
    <Box>
      <AgreementContainer>
        <StyledCheckbox
          isChecked={props.termsAccepted}
          onChange={props.toggleAcceptTerms}
          data-testid="AcceptanceOfferDetailsCheckbox"
        />
        <Box onClick={props.toggleAcceptTerms}>{props.agreeText}</Box>
      </AgreementContainer>
      <Box ml={theme.fixedSizes.spacing_300}>
        <Box>{!!props.fetchedAgreements?.length && <AgreementsList agreements={props.fetchedAgreements} />}</Box>
      </Box>
    </Box>
  )
}

const AgreementContainer = styled(Box)`
  display: flex;
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const StyledCheckbox = styled(Checkbox)`
  & span {
    margin-top: 0;
  }
`
