import { useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { ActionLogType, ReimbursementMethod } from '@genoa/domain'
import { toFormattedDollars } from '@genoa/utils'

import { useSecureLineOfCredit, useSetSimplifiedPayments } from '../../../../../hooks/secure-line-of-credit'
import { useAnalytics, useFees } from '../../../../../providers'
import { FullScreenSpinnerLoading, Gap } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { SLCHowToPayFlexBack } from './SLCHowToPayFlexBack'

export interface SLCHowToPayFlexBackContainerProps {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

export const SLCHowToPayFlexBackContainer = (props: SLCHowToPayFlexBackContainerProps) => {
  const { installments, offerMonthlyRent } = useFees()
  const { isPricingEnabled } = useSecureLineOfCredit()
  const analytics = useAnalytics()
  const [selectedOption, setSelectedOption] = useState<ReimbursementMethod | undefined>(undefined)

  const onSuccess = () => {
    analytics.logEvent(Analytics.Events.SIMPLIFIED_PAYMENTS_SETTING_CTA, {
      enabled: selectedOption === ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS,
    })

    props.onNext()
  }

  const { loading, setSimplifiedPayments } = useSetSimplifiedPayments({
    onSuccess,
  })

  const onSelectOption = (option: ReimbursementMethod) => {
    analytics.logEvent(Analytics.Events.HOW_TO_PAY_FLEX_BACK_OPTION_CTA, { option })
    setSelectedOption(option)
  }

  const onConfirm = async () => {
    if (!selectedOption) {
      return undefined
    }

    analytics.logEvent(Analytics.Events.HOW_TO_PAY_FLEX_BACK_NEXT_CTA, { option: selectedOption })
    await setSimplifiedPayments(selectedOption)
  }

  if (installments.length !== 2 || installments[0].day === -1) {
    return <FullScreenSpinnerLoading />
  }

  const [firstInstallment, secondInstallment] = installments.sort((a, b) => a.day - b.day)

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <SLCHowToPayFlexBack
        firstInstallment={firstInstallment}
        secondInstallment={secondInstallment}
        creditLine={toFormattedDollars(offerMonthlyRent)}
        isPricingEnabled={isPricingEnabled}
        confirmDisabled={!selectedOption}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        onConfirm={onConfirm}
        loadingSubmit={loading}
      />
    </BasePageLayout>
  )
}
