import { Navigate } from 'react-router-dom'

import { useUserAccountState } from '../../modules/flex2/use-state'
import * as Routes from '../constants'

export function OnboardingRoot() {
  const userAccountState = useUserAccountState()

  if (userAccountState.loaded) {
    if (userAccountState.initialized) {
      return <Navigate to={Routes.Onboarding.RESUME_ONBOARDING} replace />
    } else {
      return <Navigate to={Routes.Onboarding.CREATE_ACCOUNT} replace />
    }
  }
  return null
}
