import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { useSetOfferSchedule } from '@genoa/middle-end'
import {
  calculatePaymentDays,
  calculateSecondPaymentDays,
  filterDays,
  formatter,
  getPaymentInformation,
  handleSetReinforcementMessage,
  selectPaymentDay,
} from '@genoa/utils'

import { useAuthState } from '../../../../../contexts'
import {
  useAutopilotEligibility,
  useReduxAction,
  useReduxSelector,
  useSecureLineOfCredit,
  useShowErrorMessageModal,
} from '../../../../../hooks'
import { RootState } from '../../../../../modules'
import { OfferState, setRepaymentDayAction, setSecondRepaymentDayAction } from '../../../../../modules/flex2/offer'
import { useAnalytics, useFees } from '../../../../../providers'
import { useContent } from '../../../../../providers/content'
import * as Routes from '../../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../../components'
import { useSetScheduleRange } from '../../../onboarding/use-set-schedule-range'
import { SLCCustomizeSchedule } from './SLCCustomizeSchedule'

export interface SLCCustomizeScheduleContainer {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

export enum PaymentDays {
  FIRST_DAY = 'firstDay',
  SECOND_DAY = 'secondDay',
}

export type DaySelectedState = {
  firstDay: number | undefined
  secondDay: number | undefined
}

export const SLCCustomizeScheduleContainer = (props: SLCCustomizeScheduleContainer) => {
  const { user } = useAuthState()
  const [daysSelected, setDaysSelected] = useState<DaySelectedState>({
    firstDay: undefined,
    secondDay: undefined,
  })
  const [schedule, setSchedule] = useState<number[]>([])
  const [isLoadingRefetch, setIsLoadingRefetch] = useState(true)
  const [displayError, setDisplayError] = useState(false)
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const setRepaymentDay = useReduxAction(setRepaymentDayAction)
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { isUserEligibleForAutopilot, isLoadingEligibility } = useAutopilotEligibility()
  const setSecondRepaymentDay = useReduxAction(setSecondRepaymentDayAction)
  const { setScheduleRange } = useSetScheduleRange({
    componentName: 'SLCCustomizeScheduleContainer',
  })

  const { showErrorMessage } = useShowErrorMessageModal()
  const {
    content: { GENERIC_ERROR_MODAL },
  } = useContent()

  const {
    isLoadingSLC,
    error: errorFetchingSLC,
    isSimplifiedPaymentsEnabled,
    refetchSLC,
    isRefetchingSLC,
  } = useSecureLineOfCredit()

  const [{ loading }, setOfferSchedule] = useSetOfferSchedule()

  const fees = useFees()
  const paymentInformation = useMemo(() => getPaymentInformation(fees, true), [fees])
  useEffect(() => {
    refetchSLC()
    setIsLoadingRefetch(false)
  }, [])

  useEffect(() => {
    if (!offerState?.offer?.offer_id) {
      return
    }
    setScheduleRange(setSchedule)
  }, [offerState?.offer?.offer_id])

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreenName)
  }, [])

  useEffect(() => {
    if (!daysSelected) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_SELECT_DAY, {
      day: daysSelected?.firstDay,
      secondDay: daysSelected?.secondDay,
    })
  }, [daysSelected])

  const firstPaymentDays = useMemo(() => {
    if (schedule.length === 0) {
      return []
    }
    const [startDay, endDay] = schedule
    return calculatePaymentDays(startDay, endDay)
  }, [schedule])

  const secondPaymentDays = useMemo(() => {
    return calculateSecondPaymentDays(schedule)
  }, [schedule])

  const filteredSecondPaymentDays = useMemo(() => {
    return daysSelected.firstDay ? filterDays(secondPaymentDays, daysSelected.firstDay, true) : secondPaymentDays
  }, [secondPaymentDays, daysSelected.firstDay])

  const handleSelectPaymentDay = (day: number, paymentToUpdate: PaymentDays) => {
    selectPaymentDay(day, paymentToUpdate, setDaysSelected)
  }

  const handleConfirmSimplifiedPaymentsOn = async () => {
    if (!daysSelected.firstDay || !user?.uid) {
      return
    }

    await setOfferSchedule({
      simple_one_repayment_schedule: { repayment_day: daysSelected.firstDay },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })
    setRepaymentDay(daysSelected.firstDay)
  }

  const handleConfirmSimplifiedPaymentsOff = async () => {
    if (!daysSelected.secondDay || !daysSelected.firstDay || !user?.uid) {
      return
    }

    await setOfferSchedule({
      simple_one_repayment_schedule: {
        repayment_day: daysSelected.firstDay,
        second_repayment_day: daysSelected.secondDay,
      },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })
    setRepaymentDay(daysSelected.firstDay)
    setSecondRepaymentDay(daysSelected.secondDay)
  }

  const handleHideError = () => {
    if (
      (isSimplifiedPaymentsEnabled && daysSelected.firstDay) ||
      (!isSimplifiedPaymentsEnabled && daysSelected.firstDay && daysSelected.secondDay)
    ) {
      setDisplayError(false)
    }
  }

  const handleDisplayError = () => {
    if (
      (isSimplifiedPaymentsEnabled && !daysSelected.firstDay) ||
      (!isSimplifiedPaymentsEnabled && (!daysSelected.firstDay || !daysSelected.secondDay))
    ) {
      setDisplayError(true)
      return true
    }
  }

  useEffect(() => {
    handleHideError()
  }, [daysSelected])

  const handleClickConfirmButton = async () => {
    if (handleDisplayError()) {
      analytics.logEvent(Analytics.Events.SLC_CUSTOMIZE_SCHEDULE_CTA_ERROR)

      return
    }
    if (!daysSelected.firstDay || !user?.uid) {
      return
    }
    analytics.logEvent(Analytics.Events.SLC_CUSTOMIZE_SCHEDULE_CTA)
    try {
      if (isSimplifiedPaymentsEnabled) {
        await handleConfirmSimplifiedPaymentsOn()
      } else {
        if (daysSelected.secondDay) {
          await handleConfirmSimplifiedPaymentsOff()
        }
      }
      props.onNext()
    } catch (error: any) {
      showErrorMessage(GENERIC_ERROR_MODAL.TITLE, GENERIC_ERROR_MODAL.SUBTITLE)
    }
  }

  if (errorFetchingSLC) {
    navigate(Routes.App.TRY_AGAIN)
    return <></>
  }

  if (isLoadingEligibility || isLoadingSLC || isRefetchingSLC || isLoadingRefetch) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <SLCCustomizeSchedule
      onClickConfirmButton={handleClickConfirmButton}
      showReinforcementMessage={handleSetReinforcementMessage(schedule[1], daysSelected)}
      loading={loading}
      showApprovedModal={!isUserEligibleForAutopilot}
      paymentInformation={paymentInformation}
      daysSelected={daysSelected}
      filteredFirstPaymentDays={firstPaymentDays}
      filteredSecondPaymentDays={filteredSecondPaymentDays}
      onSelectPaymentDay={handleSelectPaymentDay}
      isSimplifiedPaymentsEnabled={isSimplifiedPaymentsEnabled}
      displayError={displayError}
      formatter={formatter}
    />
  )
}
