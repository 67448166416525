import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSpill as SupportImage } from '../../assets'
import { Headline1, PrimaryButton, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type ContactSupportProps = {
  onMessageSupport: () => unknown
  analyticsScreenName: Analytics.Screens
}

const imageSize = {
  maxHeight: '280px',
  minHeight: '246px',
}

export const ContactSupport = (props: ContactSupportProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
    <Box minH={'60px'} />
    <Flex direction="column" align="center">
      <Container align="center" justify="center">
        <SupportImage />
      </Container>

      <Box minH={'24px'} />

      <Headline1 textAlign="center">Something went wrong</Headline1>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'}>
        {' '}
        Due to technical issues, we were unable to process your application.
      </Text>

      <Box minH={'24px'} />

      <Text textAlign="center" color={'dusk'}>
        Please reach out to our support team, and someone will get in touch.
      </Text>

      <Box minH={'30px'} />

      <PrimaryButton testID={'ContactSupportMessageSupport'} onClick={props.onMessageSupport}>
        Message support
      </PrimaryButton>
    </Flex>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`
