import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, EMBED_MANUAL_PAY_FINISH } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CongratsFlag } from '../../../../../assets'
import { AppStore, Gap, Headline2, PrimaryButton, Text } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type ManualPayFinishProps = {
  handleContinue: () => void
  downPaymentAmount: string
}

export const ManualPayFinish = (props: ManualPayFinishProps) => {
  const content = deepReplaceContent(EMBED_MANUAL_PAY_FINISH, {
    amount: props.downPaymentAmount,
  })

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_MANUAL_PAY_FINISH}>
      <Container>
        <ContentContainer>
          <Gap size="spacing_200" />
          <Flex align="center" justify="center">
            <CongratsFlag />
          </Flex>

          <Gap size="spacing_50" />
          <Headline2>{content.HEADER}</Headline2>
          <Gap size="spacing_100" />

          <Text textAlign="center">{content.BODY}</Text>
          <Gap size="spacing_150" />
          <Text fontWeight="bold" textAlign="center">
            {content.NOTICE}
          </Text>
          <Gap size="spacing_200" />

          <AppStore />
          <Gap size="spacing_200" />
        </ContentContainer>
        <Box>
          <PrimaryButton onClick={props.handleContinue} testID="EmbedManualPayFinishCTAButton">
            {content.CTA}
          </PrimaryButton>
          <Gap size="spacing_225" />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`

const ContentContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
