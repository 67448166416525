import React from 'react'
import { Analytics } from '@genoa/analytics'
import { ActionLogType, FlexLinks } from '@genoa/domain'

import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { useNavigateToOnboardingPropertySelection } from '../../../../../hooks/navigation'
import { useAnalytics, useFlexLinks } from '../../../../../providers'
import { CreditBuilderValueProp } from './CreditBuilderValueProp'

export const CreditBuilderValuePropContainer = () => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()
  const navigateToPropertySelection = useNavigateToOnboardingPropertySelection()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  const onClickLearnMore = () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_VALUE_PROP_LEARN_MORE_CTA)
    flexLinks.open(FlexLinks.creditBuilderCreditScore)
  }
  const onClickContinue = async () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_VALUE_PROP_CONTINUE_CTA)
    await handleRegisterActionLog(ActionLogType.VALUE_PROP)
    navigateToPropertySelection()
  }

  return (
    <CreditBuilderValueProp
      analyticsScreenName={Analytics.Screens.ONBOARDING_CB_VALUE_PROP}
      onClickLearnMore={onClickLearnMore}
      onClickContinue={onClickContinue}
      loadingSubmit={loadingRegisterActionLog}
    />
  )
}
