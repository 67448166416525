import { useMemo } from 'react'
import { EstimatedPaymentCategory, EstimatedPaymentSchedule } from '@genoa/domain'
import { toFormattedDollars } from '@genoa/utils'

import { PaymentScheduleItemProps } from '../../../../../components'

const handlePaymentDate = (payment: EstimatedPaymentSchedule) => {
  if (payment.payment_category === EstimatedPaymentCategory.FIRST_PAYMENT) {
    return 'Today'
  } else {
    const date = new Date(payment.bill_date)
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    })
  }
}

export const useEstimatedPaymentItems = (payments: EstimatedPaymentSchedule[] | undefined) => {
  const installments =
    payments?.filter((payment) => payment.payment_category !== EstimatedPaymentCategory.MEMBERSHIP_FEE) || []

  return useMemo(() => {
    if (installments?.length !== 2) {
      return []
    }

    const [firstInstallment, secondInstallment] = installments.sort((a, b) => {
      const dateA = new Date(a.bill_date).getTime()
      const dateB = new Date(b.bill_date).getTime()
      return dateA - dateB
    })

    const firstPayment: PaymentScheduleItemProps = {
      label: '1st payment',
      amount: toFormattedDollars(firstInstallment.amount),
      lineItems: [{ label: handlePaymentDate(firstInstallment) }],
    }

    const secondPayment: PaymentScheduleItemProps = {
      label: '2nd payment',
      amount: toFormattedDollars(secondInstallment.amount),
      lineItems: [{ label: handlePaymentDate(secondInstallment) }],
    }

    return [firstPayment, secondPayment]
  }, [installments])
}
