import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'

import { useModal } from '../../../../contexts'
import { ConnectBillerType, useConnectBiller, useReduxSelector } from '../../../../hooks'
import { usePrefills } from '../../../../hooks/prefills'
import { RootState } from '../../../../modules'
import { useAnalytics } from '../../../../providers'
import { SmallText } from '../../../components'
import { RentPortalSignIn } from './RentPortalSignIn'

type RentPortalSignInContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventForgotPasswordClicked: Analytics.Events
  analyticsEventAttemptSignInYardi: Analytics.Events
  analyticsEventBackClicked: Analytics.Events
  analyticsEventCTAClicked: Analytics.Events
  analyticsEventWaitingPortalConnection: Analytics.Events
  analyticsEventStartOverClicked: Analytics.Events
  analyticsEventWhatIfNoPortalClicked: Analytics.Events
  onRestart: () => unknown
  onNext: () => unknown
}

export const V2RentPortalSignInContainer = (props: RentPortalSignInContainerProps) => {
  const analytics = useAnalytics()
  const portal = useReduxSelector((state: RootState) => state.propertyLinking.portalDetails)
  const property = useReduxSelector((state: RootState) => state.propertyLinking.property)
  const modal = useModal()
  const { resetPrefills, prefillIsActive } = usePrefills()
  const portalName = portal?.portal_name
  const propertyName = property?.name

  const handleBack = useCallback(() => {
    analytics.logEvent(props.analyticsEventBackClicked)
  }, [])

  const renderGenericErrorModal = () => {
    modal.show({
      title: 'Whoops!',
      cta: 'Got it',
      render: () => (
        <SmallText>
          Something went wrong connecting to your rent portal. Please try again or contact support if the problem
          persists.
        </SmallText>
      ),
    })
  }

  const { connecting, connectBiller } = useConnectBiller({
    componentName: 'V2RentPortalSignInContainer',
    onError: renderGenericErrorModal,
    onConnected: props.onNext,
    connectBillerType: ConnectBillerType.PORTAL,
  })

  const handleSignIn = async (data: any) => {
    analytics.logEvent(props.analyticsEventCTAClicked, {
      portal: portal?.portal_name,
      property: property?.name,
    })

    connectBiller({ signInData: data })
  }

  const handleForgotPassword = useCallback(() => {
    analytics.logEvent(props.analyticsEventForgotPasswordClicked)

    if (portal?.forgot_password_url) {
      const win = window.open(portal?.forgot_password_url, '_blank')
      win && win.focus()
    }
  }, [portal])

  const handleStartWithNewAddress = () => {
    analytics.logEvent(props.analyticsEventStartOverClicked, { portal: portalName, property: propertyName })
    props.onRestart()
    resetPrefills()
  }

  if (!portal) {
    return null
  }

  return (
    <RentPortalSignIn
      onBack={handleBack}
      onSignIn={handleSignIn}
      onForgotPassword={handleForgotPassword}
      onReset={handleStartWithNewAddress}
      portal={portal}
      property={property}
      loading={connecting}
      analyticsScreenName={props.analyticsScreenName}
      analyticsEventWhatIfNoPortalClicked={props.analyticsEventWhatIfNoPortalClicked}
      prefillIsActive={prefillIsActive}
    />
  )
}
