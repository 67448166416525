import { keyframes } from '@emotion/react'

export const slideUp = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
`

export const slideDown = keyframes`
  from { transform: translateY(0)); }
  to { transform: translateY(100%); }
`

export const appearFromCenter = keyframes`
  from { transform: scale(0.5); }
  to { transform: scale(1); }
`

export const disappearInCenter = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0.6); opacity: 0; }
`

export const fadeIn = keyframes`
  0%   { background-color: rgba(0, 0, 0, 0); }
  25%  { background-color: rgba(0, 0, 0, 0.20); }
  50%  { background-color: rgba(0, 0, 0, 0.40); }
  100% { background-color: rgba(0, 0, 0, 0.60); }
`

export const fadeOut = keyframes`
  0%   { background-color: rgba(0, 0, 0, 0.60); }
  25%  { background-color: rgba(0, 0, 0, 0.40); }
  50%  { background-color: rgba(0, 0, 0, 0.20); }
  100% { background-color: rgba(0, 0, 0, 0); }
`
