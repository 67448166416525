import { useEffect } from 'react'
import { SetupAutopilotAction } from '@genoa/domain'
import { GENERIC_ERROR_MODAL_CONTENT, RATE_LIMIT_ERROR_MODAL_CONTENT } from '@genoa/screen-content'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { useSetupAutopilotMutation } from '../../modules/flexApi'
import { useShowErrorMessageModal } from '../error-handling'

export interface UseSetAutopilotOptions {
  readonly onSuccess: () => void
}

/** @description this should only be called from onboarding */
export const useSetAutopilot = ({ onSuccess }: UseSetAutopilotOptions) => {
  const { uid } = useAuthState()
  const { showErrorMessage } = useShowErrorMessageModal()

  const [setupAutopilot, { isLoading, isSuccess, error }] = useSetupAutopilotMutation()

  useQueryError(error, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        showErrorMessage(RATE_LIMIT_ERROR_MODAL_CONTENT.TITLE, RATE_LIMIT_ERROR_MODAL_CONTENT.BODY)
        return true
      }
    },
    onAllErrors() {
      showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE)
    },
  })

  const setAutopilot = async (status: SetupAutopilotAction) => {
    if (!isLoading) {
      return setupAutopilot({ customerId: uid!, status })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      onSuccess()
    }
  }, [isSuccess])

  return {
    loading: isLoading,
    setAutopilot,
  }
}
