import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { getOfferFees, Installment, PaymentMethodType } from '@genoa/domain'
import { OfferStates } from '@genoa/middle-end'

import { useSecureLineOfCredit } from '../../hooks/secure-line-of-credit'
import { useCustomerWalletState, useOfferState, usePricingOfferState } from '../../modules/flex2/use-state'
import { useAnalytics } from '../../providers'
import { useLogger } from '../logger'

export type FeesService = {
  isCardFeePassthroughEnabled: boolean
  subscriptionFee: number
  processingFeePercentage: number
  hasPreApprovalFees: boolean
  hasOffer: boolean
  installments: Installment[]
  offerMonthlyRent: number
  creditUtilization?: number
}

export const useFees = (): FeesService => {
  const analytics = useAnalytics()

  const offerState = useOfferState()
  const pricingOfferState = usePricingOfferState()
  const customerWalletState = useCustomerWalletState()
  const { isEnabledForSLC, isPricingEnabled } = useSecureLineOfCredit()

  const defaultCard = customerWalletState?.defaultCard

  /**
   * @deprecated isCardFeePassthroughEnabled is now meaningless, will be removed in the future
   */
  const isCardFeePassthroughEnabled = true

  const paymentMethodType =
    defaultCard?.card_type === PaymentMethodType.CREDIT ? PaymentMethodType.CREDIT : PaymentMethodType.DEBIT

  const loggerV2 = useLogger('useFeesV2')

  if (!paymentMethodType) {
    loggerV2.warn('paymentMethodType', 'No paymentMethodType was provided to useFees')
  }

  const offerFees = getOfferFees(
    offerState.offer,
    paymentMethodType,
    pricingOfferState?.initialized ? pricingOfferState.pricing_offer : undefined,
    isEnabledForSLC,
    isPricingEnabled
  )

  const offerProcessingFeePercentage = offerFees.processingFeePercentage

  const offerMonthlyRent = offerFees.monthlyRent

  const subscriptionFee = offerFees.subscriptionFee

  const hasOffer = offerFees.offerState !== ''

  const hasPreApprovalFees = hasOffer && offerState.offer.hasOwnProperty('fees')

  // Analytics
  useEffect(() => {
    if (hasOffer && offerFees.offerState === OfferStates.INITIATED) {
      if (offerProcessingFeePercentage !== undefined) {
        analytics.setUserProperty(
          Analytics.UserProperties.CARD_FEE_PASSTHROUGH_PRE_PERCENTAGE,
          offerProcessingFeePercentage
        )
      }
      if (subscriptionFee !== undefined) {
        analytics.setUserProperty(Analytics.UserProperties.CARD_FEE_PASSTHROUGH_PRE_SUBSCRIPTION, subscriptionFee)
      }
    }
  }, [hasOffer, offerProcessingFeePercentage, offerFees.offerState, subscriptionFee])

  useEffect(() => {
    if (hasOffer && offerFees.offerState === OfferStates.PENDING_ACCEPT) {
      if (offerProcessingFeePercentage !== undefined) {
        analytics.setUserProperty(
          Analytics.UserProperties.CARD_FEE_PASSTHROUGH_PERCENTAGE,
          offerProcessingFeePercentage
        )
      }
      if (subscriptionFee !== undefined) {
        analytics.setUserProperty(Analytics.UserProperties.CARD_FEE_PASSTHROUGH_SUBSCRIPTION, subscriptionFee)
      }
    }
  }, [hasOffer, offerProcessingFeePercentage, offerFees.offerState, subscriptionFee])

  return {
    isCardFeePassthroughEnabled,
    subscriptionFee,
    processingFeePercentage: offerProcessingFeePercentage,
    hasPreApprovalFees,
    hasOffer,
    installments: offerFees.installments,
    offerMonthlyRent,
    creditUtilization: offerFees.creditUtilization,
  }
}

export default { useFees }
