import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OnboardingStep, useSetOfferSchedule } from '@genoa/middle-end'
import { calculatePaymentDaysWithOrdinal, filterPaymentDays } from '@genoa/utils'

import { useAuthState } from '../../../../contexts'
import { useReduxAction, useReduxSelector, useSecureLineOfCredit, useShowErrorMessageModal } from '../../../../hooks'
import { useOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { RootState } from '../../../../modules'
import { OfferState, setRepaymentDayAction, setSecondRepaymentDayAction } from '../../../../modules/flex2/offer'
import { useAnalytics } from '../../../../providers'
import { useContent } from '../../../../providers/content'
import * as Routes from '../../../../routing/constants'
import { SecondPaymentDay } from '../customize-your-schedule/second-payment-days-list/SecondPaymentList'
import { useSetScheduleRange } from '../use-set-schedule-range'
import { ChooseDate } from './ChooseDate'
import { ReinforcementMessage } from './ReinforcementMessageWidget'

export type DaySelectedState = {
  firstDay: SecondPaymentDay | undefined
  secondDay: SecondPaymentDay | undefined
}

export const ChooseDateContainer = () => {
  const { user } = useAuthState()
  const [daysSelected, setDaysSelected] = useState<DaySelectedState>({
    firstDay: undefined,
    secondDay: undefined,
  })
  const [canChooseSecondDay, setCanChooseSecondDay] = useState(false)
  const [schedule, setSchedule] = useState<number[]>([])
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const setRepaymentDay = useReduxAction(setRepaymentDayAction)
  const setSecondRepaymentDay = useReduxAction(setSecondRepaymentDayAction)
  const analytics = useAnalytics()
  const [showReinforcementMessage, setShowReinforcementMessage] = useState<ReinforcementMessage | undefined>(undefined)
  const { setScheduleRange, loadingOptions: loadingScheduleOptions } = useSetScheduleRange({
    componentName: 'ChooseDateContainer',
  })
  const navigate = useNavigate()
  const { isRefetchingSLC, isSimplifiedPaymentsEnabled } = useSecureLineOfCredit()
  const { resolveNextStep } = useOnboardingStatus()

  const { showErrorMessage } = useShowErrorMessageModal()
  const {
    content: { GENERIC_ERROR_MODAL },
  } = useContent()

  const [{ loading }, setOfferSchedule] = useSetOfferSchedule()

  useEffect(() => {
    if (!offerState?.offer?.offer_id) {
      return
    }
    setScheduleRange(setSchedule)
  }, [offerState?.offer?.offer_id])

  useEffect(() => {
    analytics.logScreenView(Analytics.Screens.CUSTOMIZE_YOUR_SCHEDULE)
  }, [])

  useEffect(() => {
    if (!daysSelected) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_SELECT_DAY, {
      day: daysSelected?.firstDay?.day,
      secondDay: daysSelected?.secondDay?.day,
    })
  }, [daysSelected])

  const firstPaymentDays = useMemo(() => {
    if (schedule.length === 0) {
      return []
    }
    const [startDay, endDay] = schedule
    return calculatePaymentDaysWithOrdinal(startDay, endDay)
  }, [schedule])

  const secondPaymentDays = useMemo(() => {
    if (schedule.length === 4) {
      const [, , startDay, endDay] = schedule
      return calculatePaymentDaysWithOrdinal(startDay, endDay)
    } else if (schedule.length > 0) {
      const [startDay, endDay] = schedule
      return calculatePaymentDaysWithOrdinal(startDay, endDay)
    }
    return []
  }, [schedule, firstPaymentDays])

  const filteredFirstPaymentDays = useMemo(() => {
    return daysSelected.secondDay
      ? filterPaymentDays(firstPaymentDays, daysSelected.secondDay, false)
      : firstPaymentDays
  }, [firstPaymentDays, daysSelected.secondDay])

  const filteredSecondPaymentDays = useMemo(() => {
    return daysSelected.firstDay ? filterPaymentDays(secondPaymentDays, daysSelected.firstDay, true) : secondPaymentDays
  }, [secondPaymentDays, daysSelected.firstDay])

  const handleSetFirstReinforcementMessage = (date: number) => {
    const scheduleDay = daysSelected?.secondDay?.day || schedule[1]

    if (date) {
      if (date <= scheduleDay - 8) {
        setShowReinforcementMessage(ReinforcementMessage.POSITIVE)
      } else if (date >= scheduleDay - 7) {
        setShowReinforcementMessage(ReinforcementMessage.NEGATIVE)
      } else {
        setShowReinforcementMessage(undefined)
      }
    }
  }

  const handleSetSecondReinforcementMessage = (date: number) => {
    const scheduleDay = daysSelected?.firstDay?.day || schedule[1]

    if (date) {
      if (date >= scheduleDay + 8) {
        setShowReinforcementMessage(ReinforcementMessage.POSITIVE)
      } else if (date <= scheduleDay + 7) {
        setShowReinforcementMessage(ReinforcementMessage.NEGATIVE)
      } else {
        setShowReinforcementMessage(undefined)
      }
    }
  }

  const handleClickSecondPaymentDay = (day: SecondPaymentDay) => {
    const dayToUpdate = canChooseSecondDay ? 'secondDay' : 'firstDay'
    setDaysSelected((currentDays) => ({
      ...currentDays,
      [dayToUpdate]: day,
    }))
    if (dayToUpdate === 'firstDay') {
      handleSetFirstReinforcementMessage(day.day)
    } else if (dayToUpdate === 'secondDay') {
      handleSetSecondReinforcementMessage(day.day)
    }
  }

  const handleConfirmRentProtectOn = async () => {
    if (!daysSelected.firstDay || !user?.uid) {
      return
    }

    await setOfferSchedule({
      simple_one_repayment_schedule: { repayment_day: daysSelected.firstDay.day },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })
    setRepaymentDay(daysSelected.firstDay.day)
  }

  const handleConfirmRentProtectOff = async () => {
    if (!daysSelected.secondDay || !daysSelected.firstDay || !user?.uid) {
      return
    }

    await setOfferSchedule({
      simple_one_repayment_schedule: {
        repayment_day: daysSelected.firstDay.day,
        second_repayment_day: daysSelected.secondDay.day,
      },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })
    setRepaymentDay(daysSelected.firstDay.day)
    setSecondRepaymentDay(daysSelected.secondDay.day)
  }

  const handleClickConfirmButton = async () => {
    if (!daysSelected.firstDay || !user?.uid) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_CONFIRM_CLICKED)
    try {
      if (isSimplifiedPaymentsEnabled) {
        await handleConfirmRentProtectOn()
        resolveNextStep(OnboardingStep.REPAYMENT_SCHEDULING, () => navigate(Routes.Onboarding.OFFER_DETAILS))
      } else {
        if (daysSelected.secondDay) {
          await handleConfirmRentProtectOff()
          resolveNextStep(OnboardingStep.REPAYMENT_SCHEDULING, () => navigate(Routes.Onboarding.OFFER_DETAILS))
        } else {
          setCanChooseSecondDay(true)
        }
      }
    } catch (error: any) {
      showErrorMessage(GENERIC_ERROR_MODAL.TITLE, GENERIC_ERROR_MODAL.SUBTITLE)
    }
  }

  return (
    <ChooseDate
      analyticsScreenName={Analytics.Screens.CHOOSE_DATE}
      onSelectSecondPaymentDay={handleClickSecondPaymentDay}
      canChooseSecondDay={canChooseSecondDay}
      onClickConfirmButton={handleClickConfirmButton}
      isConfirmButtonDisabled={!daysSelected}
      filteredFirstPaymentDays={filteredFirstPaymentDays}
      filteredSecondPaymentDays={filteredSecondPaymentDays}
      daysSelected={daysSelected}
      showReinforcementMessage={showReinforcementMessage}
      loading={loading || loadingScheduleOptions || isRefetchingSLC}
      isSimplifiedPaymentsEnabled={isSimplifiedPaymentsEnabled}
      toggleCanChooseSecondDay={() => setCanChooseSecondDay(!canChooseSecondDay)}
    />
  )
}
