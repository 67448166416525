import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useFlexLinks } from '../../../providers'
import { AlreadySignedUp } from './AlreadySignedUp'

export const AlreadySignedUpContainer = () => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const handleOpenAppleStore = useCallback(() => {
    analytics.logEvent(Analytics.Events.ALREADY_SIGNED_UP_OPEN_APP_STORE_CLICKED)
    flexLinks.open(FlexLinks.appStore)
  }, [flexLinks.open])

  const handleOpenPlayStore = useCallback(() => {
    analytics.logEvent(Analytics.Events.ALREADY_SIGNED_UP_OPEN_PLAY_STORE_CLICKED)
    flexLinks.open(FlexLinks.googlePlayStore)
  }, [flexLinks.open])

  return (
    <AlreadySignedUp
      analyticsScreenName={Analytics.Screens.ALREADY_SIGNED_UP}
      onOpenAppleStore={handleOpenAppleStore}
      onOpenPlayStore={handleOpenPlayStore}
    />
  )
}
