import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAnalytics } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { BillingAddress } from '../register-card/billing-address-types'
import { UpdateCardBillingAddress } from './UpdateCardBillingAddress'

const billingAddressSchema = yup.object({
  name: yup.string().required('Name is required'),
  streetAddress: yup.string().required('Street address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.number().required('Zip code is required').typeError('Zip code must be a number'),
})

type UpdateCardBillingAddressContainerNavigationParams = {
  billingAddressInfo: BillingAddress
}

export const UpdateCardBillingAddressContainer = () => {
  const location = useLocation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BillingAddress>({
    resolver: yupResolver(billingAddressSchema),
  })
  const navigate = useNavigate()
  const analytics = useAnalytics()

  useEffect(() => {
    analytics.logScreenView(Analytics.Screens.CARD_UPDATE_CARD_BILLING_ADDRESS)

    if (location?.state?.billingAddressInfo) {
      reset({ ...location.state.billingAddressInfo })
    }
  }, [])

  const onPressCTA = handleSubmit((billingAddress: BillingAddress) => {
    analytics.logEvent(Analytics.Events.UPDATE_BILLING_ADDRESS_CTA_CLICKED)
    navigate(Routes.Onboarding.CARD_REGISTER, {
      replace: true,
      state: {
        nextRoute: location?.state?.nextRoute,
        isNavigatingFromUpdateBillingAddress: true,
        billingAddressUpdated: billingAddress,
      },
    })
  })

  return <UpdateCardBillingAddress control={control} errors={errors} onPressCTA={onPressCTA} />
}
