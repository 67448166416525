import { useState } from 'react'
import { SLCType } from '@genoa/domain'
import {
  CLASSIC_SLC_CONFIRM_SCHEDULE_CONTENT,
  deepReplaceContent,
  SLC_CONFIRM_SCHEDULE_CONTENT,
} from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Checkbox, Flex, HStack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useAutopilotEligibility, useFlexAnywhereUser, useIsEmbed, useSecureLineOfCredit } from '../../../../../hooks'
import {
  AddChangeCardPaymentMethod,
  AutopayStatusButton,
  AutopayToggle,
  Headline2,
  PaymentScheduleItemProps,
  PrimaryButton,
  SmallText,
} from '../../../../components'
import { CardDetails } from '../../../../components/PaymentMethods/common'
import { SLCPaymentScheduleContainer } from '../payment-schedule'

export interface SLCConfirmScheduleProps {
  readonly payments: readonly PaymentScheduleItemProps[]
  readonly cardDetails: CardDetails | undefined
  readonly continueDisabled: boolean
  readonly paymentMonth: string
  readonly onAddCard: () => void
  readonly onChangeCard: () => void
  readonly onContinue: () => void
  readonly onChangeAutopay: () => void
}

export const SLCConfirmSchedule = ({
  payments,
  cardDetails,
  continueDisabled,
  paymentMonth,
  onAddCard,
  onChangeCard,
  onContinue,
  onChangeAutopay,
}: SLCConfirmScheduleProps) => {
  const theme = useTheme()
  const isFlexAnywhere = useFlexAnywhereUser()
  const { isUserAutopayEnabled } = useAutopilotEligibility()
  const { slcType } = useSecureLineOfCredit()
  const isEmbed = useIsEmbed()
  const today = getDateTime()
  const { monthLong, year } = getResumeDate(today)
  const [accepted, setAccepted] = useState(false)
  const isSLCClassic = slcType === SLCType.CLASSIC
  const showAutopayStatus = !isFlexAnywhere && !isSLCClassic
  const content = isSLCClassic
    ? deepReplaceContent(CLASSIC_SLC_CONFIRM_SCHEDULE_CONTENT, { month: monthLong!, year: `${year}` })
    : deepReplaceContent(SLC_CONFIRM_SCHEDULE_CONTENT, { month: paymentMonth })

  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
        <SmallText>
          {typeof content.SUB_HEADER === 'string' ? content.SUB_HEADER : content.SUB_HEADER.FLEX_ANYWHERE}
        </SmallText>
      </Stack>
      <Stack>
        {showAutopayStatus && <AutopayStatusButton status={isUserAutopayEnabled} onChange={onChangeAutopay} />}
        <SLCPaymentScheduleContainer payments={payments} />
      </Stack>
      <PaymentMethodStack>
        <PaymentMethodTitle color={theme.colors.dusk}>{content.PAYMENT_METHOD.TITLE}</PaymentMethodTitle>
        <AddChangeCardPaymentMethod cardDetails={cardDetails} onAddCard={onAddCard} onChangeCard={onChangeCard} />
      </PaymentMethodStack>
      {isEmbed && <AutopayToggle />}
      {isSLCClassic && (
        <HStack gap={theme.fixedSizes.spacing_100} alignItems="start">
          <Checkbox checked={accepted} onChange={(e) => setAccepted(e.target.checked)} />
          <SmallText>{content.AUTHORIZATION_AGREEMENT}</SmallText>
        </HStack>
      )}
      <Stack>
        <PrimaryButton disabled={continueDisabled || (isSLCClassic && !accepted)} onClick={onContinue}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const PaymentMethodStack = styled(Stack)`
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const PaymentMethodTitle = styled(SmallText)`
  color: ${(props) => props.theme.colors.dusk};
`
