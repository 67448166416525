import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../providers/content'
import { Attention, CheckCircleGreen } from '../../../assets'
import { SmallText } from '../../../components'
import { SecondPaymentDay } from './second-payment-days-list/SecondPaymentList'

export enum ReinforcementMessage {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export interface ReinforcementMessageWidgetProps {
  readonly daySelected?: SecondPaymentDay
  readonly showReinforcementMessage: ReinforcementMessage | undefined
}

export const ReinforcementMessageWidget = (props: ReinforcementMessageWidgetProps) => {
  const {
    content: { CUSTOMIZE_YOUR_SCHEDULE },
  } = useContent()

  if (!props.daySelected) {
    return null
  }

  if (props.showReinforcementMessage === ReinforcementMessage.POSITIVE) {
    return (
      <Wrapper data-testid="PositiveReinforcementMessage">
        <ContentContainer>
          <IconWrapper>
            <CheckCircleGreen data-testid="PositiveReinforcementMessageIcon" />
          </IconWrapper>
          <Flex direction={'column'}>
            <HeaderSmallText fontWeight="bold">{CUSTOMIZE_YOUR_SCHEDULE?.POSITIVE_FEEDBACK_HEADER}</HeaderSmallText>
            <SmallText>{CUSTOMIZE_YOUR_SCHEDULE?.POSITIVE_FEEDBACK_BODY}</SmallText>
          </Flex>
        </ContentContainer>
      </Wrapper>
    )
  }

  if (props.showReinforcementMessage === ReinforcementMessage.NEGATIVE) {
    return (
      <Wrapper data-testid="NegativeReinforcementMessage">
        <ContentContainer>
          <AttentionIconWrapper>
            <Attention data-testid="NegativeReinforcementMessageIcon" />
          </AttentionIconWrapper>
          <Flex direction={'column'}>
            <HeaderSmallText fontWeight="bold">{CUSTOMIZE_YOUR_SCHEDULE?.NEGATIVE_FEEDBACK_HEADER}</HeaderSmallText>
            <SmallText>{CUSTOMIZE_YOUR_SCHEDULE?.NEGATIVE_FEEDBACK_BODY}</SmallText>
          </Flex>
        </ContentContainer>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.md};
  padding-top: 17px;
  padding-bottom: 18px;
  border-radius: ${(props) => props.theme.radii.lg};
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`

const ContentContainer = styled(Flex)`
  flex-direction: row;
  align-content: center;
`

const AttentionIconWrapper = styled(Flex)`
  padding-top: ${(props) => props.theme.fixedSizes.sm};
  padding-right: ${(props) => props.theme.fixedSizes.md};
  align-items: center;
`

const IconWrapper = styled(Flex)`
  padding-top: ${(props) => props.theme.fixedSizes.sm};
  padding-right: ${(props) => props.theme.fixedSizes.md};
  align-items: center;
`

const HeaderSmallText = styled(SmallText)`
  padding-bottom: ${(props) => props.theme.fixedSizes.sm};
`
