import React from 'react'
import { deepReplaceContent, SLC_HOW_FLEX_WORKS_CONTENT } from '@genoa/screen-content'
import { PaymentInformation } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'

import { useFlexAnywhereUser } from '../../../../../hooks'
import { SLCHowFlexWorks as SLCHowFlexWorksImage } from '../../../../assets'
import { Gap, GuidedList, Headline2, PrimaryButton } from '../../../../components'
import { ApprovedModalContainer } from '../../../onboarding/approved'

export interface SLCHowFlexWorksProps {
  readonly onGotIt: () => unknown
  readonly paymentInformation: PaymentInformation
  readonly loadingSubmit: boolean
}

export const SLCHowFlexWorks = ({ onGotIt, paymentInformation, loadingSubmit }: SLCHowFlexWorksProps) => {
  const isFlexAnywhere = useFlexAnywhereUser()
  const content = deepReplaceContent(SLC_HOW_FLEX_WORKS_CONTENT, {
    deposit: paymentInformation.firstPaymentAmountFormatted,
    rentAmount: paymentInformation.rentAmountFormatted,
  })

  const bullets = isFlexAnywhere ? content.FLEX_ANYWHERE_BULLETS : content.FLEX_CLASSIC_BULLETS

  return (
    <Flex direction="column">
      <SLCHowFlexWorksImage width="auto" height="150px" />
      <Headline2 textAlign="center">{content.HEADER}</Headline2>
      <Gap size="spacing_150" />

      <GuidedList items={bullets.map(({ HEADER, BODY }) => ({ label: HEADER, content: BODY }))} />

      <Gap size="spacing_400" />

      <Box>
        <PrimaryButton onClick={onGotIt} processing={loadingSubmit} disabled={loadingSubmit} testID="ContinueButton">
          {content.CTA}
        </PrimaryButton>
      </Box>
      <ApprovedModalContainer paymentInformation={paymentInformation} />
    </Flex>
  )
}
