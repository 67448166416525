import { useCallback } from 'react'
import { FlexLinks } from '@genoa/domain'

const Linking = {
  openURL: (url: string, sameTab: boolean = false) => {
    if (sameTab) {
      return window.open(url)
    }

    const win = window.open(url, '_blank')
    win && win.focus()
  },
}

export const useFlexLinks = () => {
  const open = useCallback((link: FlexLinks) => {
    Linking.openURL(link)
  }, [])

  return {
    open,
  }
}
