import { useEffect } from 'react'
import { ActionLogType, ReimbursementMethod } from '@genoa/domain'
import { GENERIC_ERROR_MODAL_CONTENT, RATE_LIMIT_ERROR_MODAL_CONTENT } from '@genoa/screen-content'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { useDisableSimplifiedPaymentsMutation, useEnableSimplifiedPaymentsMutation } from '../../modules/flexApi'
import { useShowErrorMessageModal } from '../error-handling'

interface UseSetSimplifiedPaymentsProps {
  readonly onSuccess: () => void
}

export const useSetSimplifiedPayments = ({ onSuccess }: UseSetSimplifiedPaymentsProps) => {
  const { uid } = useAuthState()
  const { showErrorMessage } = useShowErrorMessageModal()

  const [enableSimplifiedPayments, { isLoading: enableIsLoading, isSuccess: enableIsSuccess, error: enableError }] =
    useEnableSimplifiedPaymentsMutation()
  const [disableSimplifiedPayments, { isLoading: disableIsLoading, isSuccess: disableIsSuccess, error: disableError }] =
    useDisableSimplifiedPaymentsMutation()

  const actionLogToCallback = async (value: ReimbursementMethod) => {
    if (value === ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS) {
      return enableSimplifiedPayments({ customerId: uid! })
    }

    if (value === ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS) {
      return disableSimplifiedPayments({ customerId: uid! })
    }
  }

  useQueryError(enableError || disableError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        showErrorMessage(RATE_LIMIT_ERROR_MODAL_CONTENT.TITLE, RATE_LIMIT_ERROR_MODAL_CONTENT.SIMPLIFIED_PAYMENTS_BODY)
        return true
      }
    },
    onAllErrors() {
      showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE)
    },
  })

  const loading = enableIsLoading || disableIsLoading

  const setSimplifiedPayments = async (value: ReimbursementMethod) => {
    if (!loading) {
      return actionLogToCallback(value)
    }
  }

  useEffect(() => {
    if (disableIsSuccess || enableIsSuccess) {
      onSuccess()
    }
  }, [enableIsSuccess, disableIsSuccess])

  return {
    loading,
    setSimplifiedPayments,
  }
}
