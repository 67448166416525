import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { mediaDevice } from '../../../../theme/media/queries'
import { PrimaryButton, SmallText, Text, TextTitle } from '../../../components'
import { Modal, ModalActions } from '../../../components/Modal'

type ApprovedModalProps = {
  onClickContinue: () => void
  visible: boolean
  showModifyUtilizationText: boolean
  firstPaymentAmountFormatted: string
  firstPaymentDisplayProportion: number
  secondPaymentAmountFormatted: string
  secondPaymentDisplayProportion: number
  rentAmountFormatted: string
}

export const ApprovedModal = ({
  onClickContinue,
  visible,
  showModifyUtilizationText,
  firstPaymentAmountFormatted,
  firstPaymentDisplayProportion,
  secondPaymentAmountFormatted,
  secondPaymentDisplayProportion,
  rentAmountFormatted,
}: ApprovedModalProps) => {
  return (
    <Modal testID="ApprovedModal" title="You’re approved!" canClose={false} onClose={() => {}} visible={visible}>
      <HeaderTextContent data-testid="ApprovedModalSubtitleValue">
        You&rsquo;ve officially been approved for a Flex credit line of {secondPaymentAmountFormatted}. Just a few more
        steps until you&rsquo;re ready to Flex your rent.
      </HeaderTextContent>
      <Box minH="30px" />
      <MonthlyBreakdownContainer>
        <BreadkdownTextTitle>Your monthly breakdown</BreadkdownTextTitle>
        <BreakdownContainer>
          <FirstPaymentContainer w={`${firstPaymentDisplayProportion}%`}>
            <PaymentNumberText>1st payment</PaymentNumberText>
            <Box />
            <PaymentAmountText data-testid="ApprovedModalFirstPaymentAmount">
              {firstPaymentAmountFormatted}
            </PaymentAmountText>
          </FirstPaymentContainer>
          <SecondPaymentContainer w={`${secondPaymentDisplayProportion}%`}>
            <PaymentNumberText>2nd payment</PaymentNumberText>
            <Box />
            <PaymentAmountText data-testid="ApprovedModalSecondPaymentAmount">
              {secondPaymentAmountFormatted}
            </PaymentAmountText>
          </SecondPaymentContainer>
        </BreakdownContainer>
        <SmallTextContent data-testid="ApprovedModalContentValue">
          For your rent bill of {rentAmountFormatted}, you&rsquo;ll pay {firstPaymentAmountFormatted} at the beginning
          of each month, and {secondPaymentAmountFormatted} later in the month.
        </SmallTextContent>
        {showModifyUtilizationText && (
          <>
            <Box minH="10px" />
            <SmallTextContent>
              After completing signup, you can adjust how your payments are split in Settings.
            </SmallTextContent>
          </>
        )}
      </MonthlyBreakdownContainer>
      <Box minH="10px" />
      <ModalActions>
        <PrimaryButton onClick={onClickContinue} testID="ApprovedModalContinueButton">
          Continue
        </PrimaryButton>
      </ModalActions>
    </Modal>
  )
}

const HeaderTextContent = styled(SmallText)`
  display: block;
  text-align: left;
  padding-left: 22px;
  font-size: 15px;
`

const SmallTextContent = styled(SmallText)`
  font-size: 12px;
  display: block;
  padding-left: 10px;
`

const MonthlyBreakdownContainer = styled(Box)`
  text-align: left;
  min-height: 80px;
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  margin: 0px 2px;
  padding: 8px 16px 16px 16px;
`
const BreadkdownTextTitle = styled(TextTitle)`
  margin: 10px;
`

const BreakdownContainer = styled(Flex)`
  text-align: center;
  min-height: 72px;
  margin-bottom: 16px;
  border-radius: 8px;
  margin: 0px 2px;
  padding: 0px 8px 16px 8px;
`

const FirstPaymentContainer = styled(Box)`
  background: ${(props) => props.theme.colors.softLilac};
  align-items: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  min-width: 40%;
  padding-top: 4px;

  @media ${mediaDevice.tablet} {
    min-width: 30%;
  }
}
`

const SecondPaymentContainer = styled(Box)`
  > p {
    color: ${(props) => props.theme.colors.white};
  }
  background: ${(props) => props.theme.colors.jewelPurple};
  align-items: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 40%;
  padding-top: 4px;

  @media ${mediaDevice.tablet} {
    min-width: 30%;
  }
}
`

const PaymentNumberText = styled(SmallText)`
  font-size: 12px;
`
const PaymentAmountText = styled(Text)`
  margin-top: -4px;
  display: block;
`
