import React, { useMemo } from 'react'
import { Installment } from '@genoa/domain'
import { ordinalSuffixFor, toDollars } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useInterestFeeInfoModal } from '../../../../../hooks/secure-line-of-credit'
import { ExtraSmallText, SmallText } from '../../../../components'
import { useOffer } from '../../use-offer'
import { MembershipFeeReason } from './MembershipFeeReason'
import { PaymentScheduleItem } from './PaymentScheduleItem'

const resolveAmount = (payment: Installment, showExtraFees?: boolean) => {
  if (payment) {
    const amount = showExtraFees ? payment.totalAmount : payment.baseAmount
    return toDollars(amount)
  }
  return toDollars(0)
}

type PaymentScheduleProps = {
  payments: Installment[]
  fee: number
  showExtraFees?: boolean
  hideChangeDate: boolean
  onLearnMore: () => unknown
  onRepaymentDayChange: () => unknown
  onMembershipLearnMore: () => unknown
  showProcessingFeePercentage: boolean
  membershipFeeReason?: string
  feeDisclaimerLabel?: string
  initialPaymentLabel: string
  isSimplifiedPaymentsEnabled?: boolean
  isEnabledForSLC?: boolean
  showInterestFee?: boolean
}

export const PaymentSchedule = ({
  payments,
  fee,
  showExtraFees,
  hideChangeDate,
  onMembershipLearnMore,
  onRepaymentDayChange,
  onLearnMore,
  showProcessingFeePercentage,
  membershipFeeReason,
  feeDisclaimerLabel,
  initialPaymentLabel,
  isSimplifiedPaymentsEnabled,
  isEnabledForSLC,
  showInterestFee,
}: PaymentScheduleProps) => {
  const offer = useOffer()
  const { showInterestFeeInfoModal } = useInterestFeeInfoModal()
  const theme = useTheme()

  const resolvePaymentBreakdown = (payment: Installment) => {
    return [
      {
        label: 'Subtotal',
        payment: toDollars(payment.baseAmount),
      },
      {
        label: `${payment.processingFeePercentage}% monthly interest (${payment.processingFeePercentage * 12}% APR)`,
        payment: toDollars(payment.processingFeeAmount),
        onTooltipPressed: () => showInterestFeeInfoModal(payment.processingFeePercentage),
      },
    ]
  }

  const showProcessingFeeDisclosure = (payment: Installment) => {
    return showProcessingFeePercentage && payment.processingFeeAmount > 0
  }

  const paymentItems = useMemo(() => {
    const membershipFeeItem = (
      <PaymentScheduleItem
        onLearnMore={onMembershipLearnMore}
        payment={`${toDollars(fee)}`}
        paymentLabel="Membership fee"
        paymentDateLabel="15th of the month"
      />
    )

    if (isEnabledForSLC && !isSimplifiedPaymentsEnabled) {
      return [
        ...payments.slice(1).map((payment, i) => (
          // eslint-disable-next-line react/jsx-key
          <PaymentScheduleItem
            onChangeDate={onRepaymentDayChange}
            hideChangeDate={hideChangeDate}
            showProcessingFeePercentage={showProcessingFeeDisclosure(payment)}
            payment={resolveAmount(payment, showExtraFees)}
            paymentBreakdown={showInterestFee ? resolvePaymentBreakdown(payment) : undefined}
            paymentLabel={`${ordinalSuffixFor(i + 1)} payment`}
            paymentDateLabel={`${ordinalSuffixFor(offer.repayment_day || payment.day)} of the month`}
            showInterestFee={showProcessingFeeDisclosure(payment)}
          />
        )),
        ...(fee ? [membershipFeeItem] : []),
        // eslint-disable-next-line react/jsx-key
        <PaymentScheduleItem
          payment={resolveAmount(payments[0], showExtraFees)}
          paymentLabel={`${ordinalSuffixFor(payments.length)} payment`}
          paymentDateLabel={`${ordinalSuffixFor(payments[0].day)} of the month`}
          onLearnMore={undefined}
          showProcessingFeePercentage={showProcessingFeeDisclosure(payments[0])}
        />,
      ]
    }

    return [
      // eslint-disable-next-line react/jsx-key
      <PaymentScheduleItem
        payment={resolveAmount(payments[0], showExtraFees)}
        paymentLabel={isSimplifiedPaymentsEnabled ? 'Deposit' : initialPaymentLabel}
        paymentDateLabel={isSimplifiedPaymentsEnabled ? 'When you pay rent' : 'When rent is due'}
        onLearnMore={undefined}
        showProcessingFeePercentage={showProcessingFeeDisclosure(payments[0])}
      />,
      ...payments.slice(1).map((payment, i) => (
        // eslint-disable-next-line react/jsx-key
        <PaymentScheduleItem
          onChangeDate={onRepaymentDayChange}
          onLearnMore={isSimplifiedPaymentsEnabled ? undefined : onLearnMore}
          hideChangeDate={hideChangeDate}
          showProcessingFeePercentage={showProcessingFeeDisclosure(payment)}
          payment={resolveAmount(payment, showExtraFees)}
          paymentBreakdown={showInterestFee ? resolvePaymentBreakdown(payment) : undefined}
          paymentLabel={isSimplifiedPaymentsEnabled ? 'Payment' : `${ordinalSuffixFor(i + 2)} payment`}
          paymentDateLabel={`${ordinalSuffixFor(offer.repayment_day || payment.day)} of the month`}
        />
      )),
      ...(fee ? [membershipFeeItem] : []),
    ]
  }, [payments, initialPaymentLabel])

  return (
    <Box>
      {isEnabledForSLC && !isSimplifiedPaymentsEnabled && (
        <>
          <PaymentScheduleBox>
            <PaymentScheduleTitle>
              <SmallText fontWeight={'bold'}>Before using Flex</SmallText>
            </PaymentScheduleTitle>

            <Divider />
            <PaymentScheduleItem
              payment={resolveAmount(payments[0], showExtraFees)}
              paymentLabel="Deposit"
              paymentDateLabel="When rent is due"
              onLearnMore={undefined}
              showProcessingFeePercentage={showProcessingFeeDisclosure(payments[0])}
            />
          </PaymentScheduleBox>
          <Box minH={theme.fixedSizes.spacing_150} />
        </>
      )}

      <PaymentScheduleBox>
        <PaymentScheduleTitle>
          <SmallText fontWeight={'bold'}>Each month</SmallText>
        </PaymentScheduleTitle>

        <Divider />

        {paymentItems.map((paymentComponent, i) => (
          <Box key={`payment${i}`}>
            {paymentComponent}
            <Box minH={theme.fixedSizes.spacing_100} />
          </Box>
        ))}

        {membershipFeeReason && (
          <MembershipFeeReasonWrapper>
            <MembershipFeeReason membershipFeeReason={membershipFeeReason} />
          </MembershipFeeReasonWrapper>
        )}

        {!showInterestFee && (
          <Box textAlign="end">
            <ExtraSmallTextStyled>{feeDisclaimerLabel}</ExtraSmallTextStyled>
          </Box>
        )}
      </PaymentScheduleBox>
    </Box>
  )
}

const PaymentScheduleBox = styled(Flex)`
  background-color: ${(props) => props.theme.colors.wildflower};
  flex-direction: column;
  border-radius: ${(props) => props.theme.radii.xl};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
`

const PaymentScheduleTitle = styled(Box)`
  width: 100%;
`

const Divider = styled(Box)`
  width: 100%;
  margin-top: ${(props) => props.theme.fixedSizes.spacing_75};
  margin-bottom: ${(props) => props.theme.fixedSizes.spacing_75};
  border-bottom-width: ${(props) => props.theme.fixedSizes.spacing_12_5};
  background-color: ${(props) => props.theme.colors.dusk};
`

const ExtraSmallTextStyled = styled(ExtraSmallText)`
  margin-left: ${(props) => props.theme.fixedSizes.spacing_150};
  color: ${(props) => props.theme.colors.dusk};
`

const MembershipFeeReasonWrapper = styled(Flex)`
  padding-top: ${(props) => props.theme.fixedSizes.spacing_50};
`
