import { useMemo } from 'react'
import { PaymentCategoryV2, PaymentStatusFull } from '@genoa/domain'
import { toFormattedDollars } from '@genoa/utils'

import { PaymentScheduleItemProps } from '../../views/components'
import { PaymentLineItemProps } from '../../views/components/PaymentSchedule/PaymentLineItem'

const handlePaymentDate = (payment: PaymentStatusFull) => {
  if (payment.category === PaymentCategoryV2.DEPOSIT) {
    return 'Today'
  } else {
    const date = new Date(payment.due_date)
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    })
  }
}

export const usePaymentStatusToSchedule = (
  deposit: PaymentStatusFull | undefined,
  repayments: PaymentStatusFull[] | undefined,
  membershipPayment?: PaymentStatusFull | undefined
) => {
  return useMemo(() => {
    if (deposit === undefined || repayments === undefined) {
      return []
    }

    const depositScheduleItem: PaymentScheduleItemProps = {
      label: deposit.display_title,
      amount: toFormattedDollars(deposit.total_amount_cents),
    }

    const repaymentScheduleItems: PaymentScheduleItemProps[] = repayments.map((repayment) => ({
      label: repayment.display_title,
      amount: toFormattedDollars(repayment.total_amount_cents),
      lineItems: [{ label: handlePaymentDate(repayment) }],
    }))

    const membershipPaymentItem: PaymentScheduleItemProps | undefined = {
      label: membershipPayment?.display_title || '',
      amount: toFormattedDollars(membershipPayment?.total_amount_cents) || '',
      lineItems: membershipPayment?.line_items.map((lineItem) => {
        return { label: lineItem.display_title }
      }) as PaymentLineItemProps[],
    }

    return [depositScheduleItem, ...repaymentScheduleItems, membershipPaymentItem]
  }, [deposit, repayments])
}
