import { ReactNode, useCallback, useEffect, useState } from 'react'
import { OfferStates, OnboardingStep, OnboardingStepStatus } from '@genoa/middle-end'

import { useIsEmbed, useUnload } from '../../hooks'
import { isBlockedOffer } from '../../hooks/flex2/risk'
import { useAccount } from '../../hooks/use-account'
import { useOfferState, useOnboardingStatusState } from '../../modules/flex2/use-state'
import { AlreadySignedUpContainer } from '../../views/pages/already-signed-up/AlreadySignedUpContainer'
import { EmbedCongrats } from '../../views/pages/common/embed'
import { NotApprovedContainer } from '../../views/pages/not-approved/NotApprovedContainer'
import { OnboardingRoutes } from './OnboardingRoutes'

export function OnboardingRouteContainer() {
  const [isMovingOutOnboarding, setIsMovingOutOnboarding] = useState(false)
  const [Component, setComponent] = useState<ReactNode>()

  const onboardingStatusState = useOnboardingStatusState()
  const offerState = useOfferState()
  const isEmbed = useIsEmbed()

  const { isActive } = useAccount()

  useUnload(
    useCallback(
      (e: Event) => {
        if (!isMovingOutOnboarding) {
          e.preventDefault()

          //@ts-ignore
          e.returnValue = 'Are you sure you want to leave?' //For old browsers

          return 'Are you sure you want to leave?'
        }
      },
      [isMovingOutOnboarding]
    )
  )

  // Resolves navigation component override for based on offer state
  useEffect(() => {
    if (isActive) {
      setIsMovingOutOnboarding(true)
      return setComponent(isEmbed ? <EmbedCongrats /> : <AlreadySignedUpContainer />)
    }
    const creditEligibilityCompleted = onboardingStatusState?.steps.find(
      (step) => step.step === OnboardingStep.CREDIT_ELIGIBILITY_CHECK && step.status === OnboardingStepStatus.COMPLETE
    )

    const deniedStates = [OfferStates.DENIED.toString(), OfferStates.WITHDRAWN.toString()]

    const shouldShowDeniedOffer =
      onboardingStatusState.loaded &&
      offerState.initialized &&
      deniedStates.includes(offerState.offer?.offer_state) &&
      offerState.offer.withdrawal_reason !== 'Expiration' &&
      creditEligibilityCompleted

    if (shouldShowDeniedOffer || isBlockedOffer(offerState)) {
      setIsMovingOutOnboarding(true)
      return setComponent(<NotApprovedContainer />)
    }

    setComponent(<OnboardingRoutes />)
    setIsMovingOutOnboarding(false)
  }, [isActive, offerState, onboardingStatusState])

  return <>{Component}</>
}
