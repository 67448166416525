import React, { useMemo } from 'react'
import { Analytics } from '@genoa/analytics'
import { ActionLogType } from '@genoa/domain'
import { getPaymentInformation } from '@genoa/utils'

import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useFees } from '../../../../../providers'
import { Gap } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { SLCHowFlexWorks } from './SLCHowFlexWorks'

export interface SLCHowFlexWorksContainerProps {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

export const SLCHowFlexWorksContainer = (props: SLCHowFlexWorksContainerProps) => {
  const analytics = useAnalytics()
  const fees = useFees()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  const paymentInformation = useMemo(() => getPaymentInformation(fees, true), [fees])

  const handleGotIt = async () => {
    analytics.logEvent(Analytics.Events.OON_HOW_FLEX_WORKS_CTA_CLICKED)
    await handleRegisterActionLog(ActionLogType.APPROVE_CONFIRMATION)
    props.onNext()
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <SLCHowFlexWorks
        loadingSubmit={loadingRegisterActionLog}
        onGotIt={handleGotIt}
        paymentInformation={paymentInformation}
      />
    </BasePageLayout>
  )
}
