import { Route } from 'react-router-dom'

import {
  OnboardingSLCAutopaySetupContainer,
  OnboardingSLCConfirmScheduleContainer,
  OnboardingSLCCongratsContainer,
  OnboardingSLCCustomizeScheduleContainer,
  OnboardingSLCHowFlexWorksContainer,
  OnboardingSLCHowToPayFlexBackContainer,
  OnboardingSLCSoftCreditCheckContainer,
} from '../../views/pages/onboarding/schedule-slc'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

export const SCHEDULE_SETUP_SLC_ROUTES = (
  <>
    <Route key="how-flex-works" path={routeSlugs.HOW_FLEX_WORKS} element={<OnboardingSLCHowFlexWorksContainer />} />
    <Route
      key="how-to-pay-flex-back"
      path={routeSlugs.HOW_TO_PAY_FLEX_BACK}
      element={<OnboardingSLCHowToPayFlexBackContainer />}
    />
    <Route
      key="customize-schedule"
      path={routeSlugs.CUSTOMIZE_SCHEDULE}
      element={<OnboardingSLCCustomizeScheduleContainer />}
    />
    <Route key="autopay-setup" path={routeSlugs.AUTOPAY_SETUP} element={<OnboardingSLCAutopaySetupContainer />} />
    <Route key="offer-details" path={routeSlugs.OFFER_DETAILS} element={<OnboardingSLCConfirmScheduleContainer />} />
    <Route key="congrats" path={routeSlugs.CONGRATS} element={<OnboardingSLCCongratsContainer />} />
    <Route
      key="soft-credit-check"
      path={routeSlugs.SOFT_CREDIT_CHECK}
      element={<OnboardingSLCSoftCreditCheckContainer />}
    />
  </>
)
